<template>
    <div class="col-12">
        <div class="jumbotron py-4 shadow-sm">
            <h1 class="h3 font-weight-light">
                Olá {{ name }}
            </h1>
            <p class="lead m-0">
                {{ date }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                date: '',
            }
        },
        props: {
            name: String,
        },
        mounted() {
            this.date = this.getDate();
        },
        methods: {
            getDate() {
                return `Hoje é dia ${moment().format('LL')}`;
            }
        }
    }
</script>