<template>
	<div>
		<div class="box-qrcode" v-for="student in students">
			<div class="body">
				<div class="texts">
					<h5 class="title">JARDIM NOTÁVEL</h5>
					<ul>
						<li>{{ student.name }}</li>
						<li>{{ (student.ra) ? student.ra : '.' }}</li>
						<li v-if="student.data">
							{{ (student.data.classroom) ? student.data.classroom.name : '.' }}
						</li>
						<li v-if="student.data">
							{{ student.data.school.name }}
						</li>
						<li v-if="student.responsible">.</li>
						<li v-if="student.responsible">
							{{ student.responsible.name ?? student.responsible.email }}
						</li>
					</ul>
				</div>
				<img :src="'https://chart.googleapis.com/chart?chs=150x150&cht=' +
					'qr&&chld=H|0&chl=' + student.login + '.' + student.pass"
					class="qrcode"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				students: [],
			}
		},
		props: {
			classroomId: String,
			studentId: String,
		},
		mounted() {
			this.load();
		},
		updated() {
			this.print();
		},
		methods: {
			load() {
				let param = (this.classroomId)
					? `classroom_id=${this.classroomId}`
					: `student_id=${this.studentId}`;

				axios.get(`/web/students/print?${param}`)
					.then(res => {
						this.students = res.data;
					})
					.catch(err => {});
			},
			print() {
				var page = window.open();

				if (page) {
					page.document.write($('html').html());
					page.document.close();
					page.onload = function () {
						page.print();
						page.close();
						history.back();
					}
				}
			}
		}
	}
</script>
