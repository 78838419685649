<template>
    <div>
        <app-page-header
			icon="fa fa-user"
			title="Cadastrar Gerente"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/managers', title: 'Gerentes' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
                                <div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb"
                                        class="thumb img-thumbnail img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Inserir Imagem"
                                        :outline="false"
                                        :withTitle="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                </div>
                                <app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome Completo"
                                    placeholder="Nome Completo"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="true"
                                    :callback="generateLogin"
                                ></app-input>
                                <app-input
                                    name="user[email]"
                                    label="E-mail"
                                    placeholder="E-mail"
                                    :maxLength="300"
                                    :required="true"
                                    type="email"
                                ></app-input>
                                <app-input
                                    name="user[login]"
                                    label="Usuário"
                                    placeholder="Usuário"
                                    :maxLength="11"
                                    :minLength="4"
                                    :required="true"
                                    title="apenas letras e números, mínimo 4 caracteres"
                                    pattern="[A-Za-z0-9]{4,12}"
                                    :value="tempLogin"
                                ></app-input>
                                <app-input
                                    name="user[password]"
                                    label="Senha"
                                    placeholder="Senha"
                                    :maxLength="10"
                                    :minLength="6"
                                    :required="true"
                                    title="mínimo 6 caracters"
                                    type="password"
                                ></app-input>
                                <app-input
                                    name="confirm_password"
                                    label="Confirmar Senha"
                                    placeholder="Confirmar Senha"
                                    :maxLength="10"
                                    :minLength="6"
                                    :required="true"
                                    title="mínimo 6 caracters"
                                    type="password"
                                ></app-input>
                                <app-input
                                    title="mínimo 14 caracters"
                                    name="phone"
                                    label="Telefone"
                                    placeholder="Telefone"
                                    mask="(##) ####-####"
                                    :maxLength="15"
                                    :minLength="14"
                                    :required="true"
                                ></app-input>
                                <app-input
                                    title="mínimo 14 caracters"
                                    name="cell_phone"
                                    label="Celular"
                                    placeholder="Celular"
                                    mask="(##) #####-####"
                                    :maxLength="15"
                                    :minLength="14"
                                ></app-input>
                                <app-select
                                    name="profile_id"
                                    label="Setor"
                                    title="Setor"
                                    :required="true"
                                    :options="sectors"
                                ></app-select>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>

                        <app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import ImageSelect from '../ImageSelectComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
        },
        data() {
            return {
                DEFAULT_IMAGE: '/assets/img/default_thumbs/user.png',
                tempImage: '',
                tempLogin: '',
                sectors: [],
            }
        },
        props: {
            backUrl: String,
        },
        mounted() {
            this.tempImage = this.DEFAULT_IMAGE;
            this.loadSectors();
        },
        methods: {
            openEditImage() {
                this.$refs.imageSelect.open();
            },
			imageLoaded(image) {
                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
            loadSectors() {
                axios.get(`/web/profiles/getSectors`)
                    .then(res => {
                        this.sectors = res.data;
                    })
                    .catch(err => {});
            },
            generateLogin(name) {
                axios.get(`/web/login/generateLogin/${name}`)
                    .then(res => {
                        this.tempLogin = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('thumb', this.$refs.imageSelect.file);

                    axios.post(`/web/managers`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/managers';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>