<template>
    <div v-if="responsible">
        <app-page-header
            title="Configurações"
            icon="fa fa-cog"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Configurações' },
            ]"
        ></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
                                <app-toggle
									label="Indicações automáticas"
									name="auto_indication"
									:value="responsible.setting.auto_indication"
								></app-toggle>
                                <app-toggle
									label="Bloquear se houver indicação"
									name="lock_while_indication"
									:value="responsible.setting.lock_while_indication"
								></app-toggle>
                                <app-select
                                    name="indication_time"
                                    label="Indicação automática a cada"
                                    title="Indicação automática a cada"
                                    empty="false"
                                    :required="true"
                                    :options="hours"
                                    :value="responsible.setting.indication_time ?? '12:00'"
                                ></app-select>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button
                                        type="update"
                                        :callback="onSubmit"
                                    ></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Toggle from '../forms/ToggleComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-toggle': Toggle,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
            return {
                responsible: null,
                hours: []
            };
        },
        props: {
            id: Number,
            backUrl: String,
        },
        mounted() {
            this.loadHours();
            this.load();
        },
        methods: {
            load() {
                axios.get(`/web/responsibles/${this.id}`)
                    .then(res => {
                        this.responsible = res.data;
                    })
                    .catch(err => {});
            },
            loadHours() {
                axios.get('/web/classroom-settings/getHours')
                    .then(res => {
                        this.hours = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);

				formData.append('_method', 'PATCH');

				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					Flash('error', validate);
				} else {
					axios.post(`/web/responsibles/${this.responsible.id}/settings`, formData)
						.then(res => {
							Swal.fire({
								title: 'Atualizado com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/';
							});
						})
						.catch(err => {
							Flash('error', 'Erro ao tentar atualizar registro!');
						});
				}
            }
        }
    }
</script>