var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"title":"Salas","icon":"fa fa-graduation-cap","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Salas' },
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"classrooms","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            print: true,
            columnsToPrint: [1,2,3,4],
            ajax: {
                url: '/web/classrooms',
            },
            columns: _vm.columns,
            searchCols: _vm.initFilter,
            btFilter: _vm.schools.length > 1
        },"actions":{
            qrcode: {
                allow: _vm.allows.qrcode,
                url: '/students/print_qrcode?classroom_id=*',
            },
            pass: {
                allow: _vm.allows.pass,
                url: '/students/print_password?classroom_id=*',
            },
            student: {
                allow: _vm.allows.student,
                url: '/students?classroom_id=*',
            },
            create: {
                allow: _vm.allows.create,
                url: '/classrooms/create',
            },
            show: {
                allow: _vm.allows.show,
                callback: _vm.onShow,
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/classrooms/*/edit',
            },
            settings: {
                allow: _vm.allows.settings,
                url: '/classrooms/*/settings'
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*',
            },
        },"filters":[
            {
                name: 'school_id',
                type: 'select',
                index: 3,
                options: _vm.schools,
                liveSearch: true,
                value: _vm.school_id,
            }
        ]}}),_vm._v(" "),_c('app-classroom-show',{ref:"classroomShow",attrs:{"isTeacher":this.isTeacher}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }