var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Relatório de Alunos","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Alunos' },
        ]}}),_vm._v(" "),(_vm.isManager)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('app-input-toggle',{attrs:{"name":"reportType","on-style":"secondary","off-style":"secondary","on-label":"Escola","off-label":"Responsável","checked":true,"callback":_vm.onChangeReportType}})],1)])]):_vm._e(),_vm._v(" "),(_vm.filterReady)?_c('app-filter',{attrs:{"filters":[
            {
                icon: 'fa fa-calendar',
                name: 'year',
                value: _vm.year,
                type: 'input',
                datepicker: true,
                index: 5,
            },
            {
                icon: 'fa fa-university',
                name: 'school_id',
                data: _vm.schools,
                index: 7,
                value: _vm.school_id,
                class: 'col-12 col-xl-4 col-md-6',
                callback: _vm.loadClassrooms,
                disabled: _vm.isResponsible || _vm.responsibleFilterEnabled,
            },
            {
                icon: 'fa fa-graduation-cap',
                name: 'classroom_id',
                data: _vm.classrooms,
                index: 6,
                value: _vm.classroom_id,
                class: 'col-12 col-xl-4 col-md-6',
                disabled: _vm.isResponsible || _vm.responsibleFilterEnabled,
            },
            {
                icon: 'fa fa-user',
                name: 'responsible_id',
                data: _vm.responsibles,
                index: 8,
                value: _vm.responsible_id,
                class: 'col-12 col-xl-4 col-md-6',
                disabled: !_vm.responsibleFilterEnabled,
            }
        ],"onChangeCallback":_vm.onFilter}}):_vm._e(),_vm._v(" "),(_vm.filterReady)?_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            print: true,
            btFilter: false,
            columnsToPrint: [0,1,3],
            ajax: {
                url: '/report/students'
            },
            columns: _vm.columns,
            searchCols: [
                null,
                null,
                null,
                null,
                null,
                { search: this.year },
                { search: this.classroom_id },
                { search: this.school_id },
            ],
        },"actions":{
            chart: {
                allow: _vm.allows.student,
                url: '/students/*/report'
            },
        }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }