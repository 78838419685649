var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_c('i',{staticClass:"fa fa-graduation-cap"}),_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.title)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.onClose}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row content"},[(_vm.loading)?_c('div',{staticClass:"col-12 text-center"},[_c('img',{attrs:{"src":"/assets/img/loading.svg"}})]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(0),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.name))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.year.title))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(2),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.school.name))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(3),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.active_text))])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_vm._m(4),_vm._v(" "),_c('ul',{staticClass:"list-group"},[(!_vm.data.teachers.length)?_c('p',{staticClass:"alert alert-danger"},[_vm._v("\n                                            Sala sem professor\n                                        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.data.teachers),function(teacher){return _c('a',{class:[
                                                {disabled: _vm.isTeacher},
                                                'list-group-item btn btn-outline-info d-flex',
                                                'justify-content-between'
                                            ],attrs:{"href":'/teachers?id=' + teacher.id}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(teacher.name)+"\n\t\t\t\t\t\t\t\t\t\t\t"),_c('span',{staticClass:"badge badge-pill px-3",class:[
													teacher.active
														? 'badge-success'
														: 'badge-secondary'
											]},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(teacher.active_text)+"\n\t\t\t\t\t\t\t\t\t\t\t")])])})],2)])])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Nome")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Ano")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Escola")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Status")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Professores")])])
}]

export { render, staticRenderFns }