<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Relatório Detalhado"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/students/report', title: 'Relatório de Alunos' },
                { href: '', title: 'Relatório Detalhado' },
            ]"
        ></app-page-header>

        <div v-if="student" class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <div class="row">
                            <div class="col-12">
                                <b>Aluno:</b> {{ student.name }}
                            </div>
                            <div v-if="student.data && student.data.classroom" class="col-12">
                                <b>Sala:</b> {{ student.data.classroom.name }}
                            </div>
                            <div v-if="student.data && student.data.school" class="col-12">
                                <b>Escola:</b> {{ student.data.school.name }}
                            </div>
                            <div v-if="student.responsible" class="col-12">
                                <b>Responsável:</b> {{ student.responsible.name }}
                            </div>
                            <div v-if="!student.data" class="col-12 mt-2">
                                <div class="alert alert-warning text-center m-0">
                                    Aluno sem registro para esse ano!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-filter
            :filters="[
                {
                    icon: 'fa fa-calendar',
                    name: 'year',
                    value: year,
                    type: 'input',
                    datepicker: true,
                },
                {
                    icon: 'fa fa-th-list',
                    name: 'module_id',
                    data: moduleList,
                    value: module_id,
                    class: 'col-12 col-xl-4 col-md-6'
                }
            ]"
            :onChangeCallback="onFilter"
        ></app-filter>

        <div class="row">
            <app-module-box
                v-for="module in modules"
                :key="module.id"
                :module="module"
                :openChart="openChart"
            ></app-module-box>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <div class="tile">
					<div class="tite-body">
                        <app-link type="back" :url="backUrl"></app-link>
                    </div>
                </div>
            </div>
        </div>

        <app-latest-scores-chart
            ref="chart"
            :student-id="student.id"
        ></app-latest-scores-chart>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
    import ModuleBox from '../modules/ModuleBoxComponent';
    import LatestScoresChart from './LatestScoresChartComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-filter': Filter,
            'app-module-box': ModuleBox,
            'app-latest-scores-chart': LatestScoresChart,
            'app-link': Link,
        },
        data() {
            return {
                student: '',
                year: new Date().getFullYear(),
                module_id: null,
                moduleList: [],
                modules: [],
            };
        },
        props: {
            studentId: Number,
            backUrl: String,
        },
        mounted() {
            this.loadModules();
        },
        methods: {
            loadStudent() {
                axios.get(`/web/students/${this.studentId}`, { params: { year: this.year } })
                    .then(res => {
                        this.student = res.data;
                    })
                    .catch(err => {});
            },
            loadModules() {
                axios.get('/web/modules/list?toTags=true')
                    .then(res => {
                        this.moduleList = res.data;
                        this.moduleList.unshift({ label: 'TODOS OS MÓDULOS', value: '' });
                        this.module_id = '';

                        this.onFilter([
                            { name: 'year', value: this.year },
                            { name: 'module_id', value: this.module_id },
                        ]);
                    })
                    .catch(err => {});
            },
            load(data) {
                this.modules = [];

                axios.get(`/report/modules/student/${this.studentId}`, { params: data })
                    .then(res => {
                        this.modules = res.data;
                    })
                    .catch(err => {});
            },
            onFilter(data) {
                this.year = data.filter((o) => o.name == 'year')[0].value;
                this.module_id = data.filter((o) => o.name == 'module_id')[0].value;

                if (this.module_id != null) {
                    let params = {};
                    
                    for (let o of data) {
                        params[o.name] = o.value;
                    }

                    this.loadStudent();
                    this.load(params);
                }
            },
            openChart(activityId) {
                this.$refs.chart.load(activityId);
            }
        }
    }
</script>