<template>
    <div>
        <app-page-header
            title="Gerentes"
            icon="fa fa-user"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Gerentes' },
            ]"
        ></app-page-header>

        <app-datatable
            entity="managers"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="5"
            :options="{
                ajax: {
                    url: '/web/managers',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/managers/create',
                },
                show: {
                    allow: allows.show,
                    callback: onShow,
                },
                edit: {
                    allow: allows.edit,
                    url: '/managers/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*',
                }
            }"
        ></app-datatable>

        <app-manager-show ref="managerShow"></app-manager-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';
    import ManagerShow from './ManagerShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
            'app-manager-show': ManagerShow,
        },
        props: {
            allows: Object,
            columns: Array,
        },
        methods: {
            onShow(id) {
                this.$refs.managerShow.open(id);
            }
        }
    }
</script>