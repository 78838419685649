var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-graduation-cap","title":"Relatório de Salas","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Salas' },
        ]}}),_vm._v(" "),(_vm.filters.length)?_c('app-filter',{attrs:{"filters":_vm.filters,"onChangeCallback":_vm.onFilter}}):_vm._e(),_vm._v(" "),(_vm.school_id)?_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            print: true,
            btFilter: false,
            columnsToPrint: [0,1,2],
            ajax: {
                url: '/report/classrooms'
            },
            columns: _vm.columns,
            searchCols: [
                null,
                null,
                null,
                null,
                null,
                { search: this.year },
                { search: this.school_id },
            ],
        },"actions":{
            student: {
                allow: _vm.allows.student,
                url: '/students/report?classroom_id=*'
            },
        }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }