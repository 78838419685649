<template>
	<div>
		<app-page-header
			icon="fa fa-gamepad"
			title="Jogos"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '', title: 'Jogos' },
			]"
		></app-page-header>

		<app-datatable
			entity="games"
			:allow-enable-multi="allows.enableMulti"
			:allow-disable-multi="allows.disableMulti"
			:allow-delete-multi="allows.deleteMulti"
			:withStatusFilter="true"
			:options="{
				ajax: {
					url: '/web/games',
				},
				columns: columns,
			}"
			:actions="{
				create: {
					allow: allows.create,
					url: '/games/create',
				},
				edit: {
					allow: allows.edit,
					url: '/games/*/edit',
				},
				show: {
					allow: allows.show,
					callback: onShow,
				},
				delete: {
					allow: allows.delete,
					url: '*',
				},
				preview: {
					allow: allows.preview,
					callback: onPreview,
				},
				indicate: {
                    allow: allows.indicate,
                    url: '/indications/create?activity_id=@',
                },
			}"
		></app-datatable>

		<app-game-show ref="gameShow"></app-game-show>
		<app-game-preview ref="gamePreview"></app-game-preview>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
	import GameShow from './GameShowComponent';
	import GamePreview from './GamePreviewComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
			'app-game-show': GameShow,
			'app-game-preview': GamePreview,
		},
		props: {
			params: Object | Array,
			allows: Object,
			columns: Array,
		},
		mounted()
		{
			if (this.params.hasOwnProperty('id')) {
				this.onShow(this.params.id);
			}
		},
		methods: {
			onShow(id) {
				this.$refs.gameShow.open(id);
			},
			onPreview(id) {
				this.$refs.gamePreview.load(id);
			}
		}
	}
</script>