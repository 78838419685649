<template>
    <div>
        <app-page-header
            icon="fa fa-thumb-tack"
            :title="'Relatório da Indicação: ' + indicationId"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/indications/report', title: 'Relatório de Indicações' },
                { href: '', title: 'Relatório Detalhado' },
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            :options="{
                print: true,
                btFilter: false,
                ajax: {
                    url: '/report/students/indication/' + indicationId
                },
                columns: columns,
            }"
            :actions="{}"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
			'app-datatable': DataTable,
        },
        props: {
            indicationId: Number,
            columns: Array,
        }
    }
</script>