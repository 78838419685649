<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-ticket"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
                <form ref="form">
                    <div class="modal-body">
                        <div class="row">
                            <app-textarea
                                ref="message"
                                name="message"
                                placeholder="Mensagem"
                                :required="true"
                                :maxLength="300"
                                :rows="5"
                                resize="none"
                                containerClass="col-12"
                            ></app-textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <app-button
                            icon="paper-plane"
                            title="Enviar"
                            :callback="onSubmit"
                        ></app-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import TextArea from '../forms/TextareaComponent';
    import Button from '../forms/ButtonComponent';

    export default {
        components: {
            'app-textarea': TextArea,
            'app-button': Button,
        },
        data() {
            return {
                modal: HTMLElement,
                id: Number,
                title: '',
            }
        },
        props: {
            defaultTitle: { type: String, default: 'Ticket' },
            onSent: Function,
        },
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {
                this.id = id;
                this.modal = $(this.$refs.modal);
                this.modal.modal('show');
                this.title = `${this.defaultTitle}: ${id} - Nova Mensagem`;
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/tickets/${this.id}/sendMessage`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Mensagem enviada com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                this.$refs.message.input = '';
                                this.modal.modal('hide');
                                this.onSent();
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>