<template>
	<div v-if="celebrations.length">
		<app-page-header
			icon="fa fa-calendar-check-o"
			:title="title"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/celebrations', title: 'Celebrações' },
				{ href: '', title: 'Configurar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
							<input
								v-if="schools.length"
								type="hidden"
								name="school_id"
								:value="school.value"
							>
							<div class="row">
								<div
									v-for="celebration in celebrations"
									class="col-12 col-xl-4 col-md-6 mb-3"
								>
									<button
										type="button"
										class="btn form-control btn-card"
										:class="[
											celebration.enabled
												? 'btn-success text-white'
												: 'btn-light text-muted'
										]"
										@click="celebration.enabled = !celebration.enabled"
									>
										<h6 class="m-0">{{ celebration.title }}</h6>
										<span class="pull-right">
											{{ celebration.start_date }} a
											{{ celebration.finish_date }}
										</span>
										<input
											v-if="celebration.enabled"
											name="celebrations[]"
											type="hidden"
											:value="celebration.id"
										>
									</button>
								</div>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Select from '../forms/SelectComponent';
	import Toggle from '../forms/ToggleComponent';
	import Button from '../forms/ButtonComponent';
	import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-select': Select,
			'app-toggle': Toggle,
			'app-button': Button,
			'app-link': Link,
		},
		data() {
			return {
				celebrations: [],
				schools: [],
				school: { value: '', label: '' },
				title: '',
			}
		},
		props: {
			profileCode: String,
            backUrl: String,
			defaultTitle: { type: String, default: 'Celebrações' },
        },
		mounted() {
			this.title = this.defaultTitle;

			if (this.profileCode == 'responsible') {
				this.loadCelebrations();
			} else {
				this.loadSchools();
			}
		},
		methods: {
			loadSchools() {
				axios.get('/web/schools/list?toTags=true')
					.then(res => {
						this.schools = res.data;
						let data = {};
						
						for (let s of this.schools) {
							data[s.value] = s.label;
						}

						if (this.schools.length) {
							Swal.fire({
								type: 'info',
								title: 'Selecione uma escola',
								input: 'select',
								inputOptions: data,
								allowOutsideClick: false,
							}).then(async res => {
								this.school = this.schools.filter(s => s.value == res.value)[0];

								this.title = `${this.title} - ${this.school.label}`;
								this.loadCelebrations(this.school.value);
							});
						} else {
							this.loadCelebrations();
						}
					})
					.catch(err => {});
			},
			loadCelebrations(school_id = null) {
				let param = school_id ? `?school_id=${school_id}` : '';

				axios.get(`/web/celebrations/byUser${param}`)
					.then(res => {
						this.celebrations = res.data;
					})
					.catch(err => {});
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/celebrations/settings`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/celebrations';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
			}
		}
	}
</script>