<template>
    <div>
        <app-page-header
            icon="fa fa-th-list"
            title="Módulos"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Módulos' }
            ]"
        ></app-page-header>

        <app-datatable
            entity="modules"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/modules'
                },
                columns: columns
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/modules/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: allows.edit,
                    url: '/modules/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                },
                class_plan: {
                    allow: allows.classPlan,
                    url: '*',
                }
            }"
        ></app-datatable>

        <app-module-show ref="moduleShow"></app-module-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
	import ModuleShow from './ModuleShowComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
			'app-module-show': ModuleShow
		},
        props: {
            allows: Object,
            columns: Array,
        },
        methods: {
            onShow(id) {
                this.$refs.moduleShow.open(id);
            }
        }
    }
</script>