var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Relatório de Professores","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Professores' },
        ]}}),_vm._v(" "),(_vm.filters.length)?_c('app-filter',{attrs:{"filters":_vm.filters,"onChangeCallback":_vm.onFilter}}):_vm._e(),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            print: true,
            btFilter: false,
            columnsToPrint: [0,1,2],
            ajax: {
                url: '/report/teachers'
            },
            columns: _vm.columns,
            searchCols: _vm.getSearchInit(),
        },"actions":{
            chart: {
                allow: _vm.allows.chart,
                url: '/classrooms/report?teacher_id=*'
            },
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }