<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title">
						<i class="fa fa-university"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
                </div>
                <div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="row">
                                <div class="col-12 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="data.thumb"
                                        class="img-thumbnail img-rounded img-fluid"
                                    >
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>Nome</strong></p>
                                    <p>{{ data.name }}</p>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>Razão Social</strong></p>
                                    <p>{{ data.company_name }}</p>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>CNPJ</strong></p>
                                    <p>{{ data.cnpj }}</p>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>E-mail</strong></p>
                                    <p>{{ data.email }}</p>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>Telefone</strong></p>
                                    <p>{{ data.phone }}</p>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>Status</strong></p>
                                    <p>{{ data.active_text }}</p>
                                </div>
                                <div class="col-12">
                                    <div class="card card-light">
                                        <div class="card-header">Endereço</div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p><strong>CEP</strong></p>
                                                    <p>{{ data.zip_code }}</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <p><strong>Estado</strong></p>
                                                    <p>{{ data.city.state.initials }}</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <p><strong>Cidade</strong></p>
                                                    <p>{{ data.city.name }}</p>
                                                </div>
                                                <div class="col-md-3">
                                                    <p><strong>Logradouro</strong></p>
                                                    <p>{{ data.address }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6 mt-2">
                                    <p><strong>Diretor</strong></p>
                                    <p>
                                        {{
                                            (data.directors.length) ? data.directors[0].name : '-'
                                        }}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-12">
                                    <div class="list-group text-center">
                                        <a
                                            :href="'/teachers?school_id=' + data.id" 
                                            class="list-group-item list-group-item-action list-group-item-info"
                                        >
                                            Professores ({{ data.teachers_count }})
                                        </a>
                                        <a
                                            :href="'/classrooms?school_id=' + data.id"
                                            class="list-group-item list-group-item-action list-group-item-info"
                                        >
                                            Salas ({{ data.classrooms_count }})
                                        </a>
                                        <a
                                            :href="'/students?school_id=' + data.id"
                                            class="list-group-item list-group-item-action list-group-item-info"
                                        >
                                            Alunos ({{ data.students_count }})
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modal: HTMLElement,
				title: '',
				loading: true,
                data: {},
            }
        },
        props: {
			defaultTitle: { type: String, default: 'Escola' }
		},
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {
                this.loading = true;
                this.modal = $(this.$refs.modal);
                this.title = 'Carregando...';
                this.modal.modal('show');

                axios.get(`/web/schools/${id}`)
                    .then(res => {
                        this.loading = false;
                        this.data = res.data;
                        this.title = `${this.defaultTitle}: ${this.data.id}`;
                    })
                    .catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			},
			goBack() {
				window.history.go(-1);
			}
        }
    }
</script>