<template>
	<div class="col-12 col-xl-3 col-lg-6 col-md-6">
		<div class="card card-light mb-2 shadow-sm">
			<div class="card-header d-flex align-items-center justify-content-between">
				<span>{{ module.name }}</span>
				<app-toggle
					containerClass=""
					subClass=""
					:callback="onselectModuleGroup"
					:value="checked"
				></app-toggle>
			</div>
			<div class="card-body">
				<div class="row" ref="activities">
					<app-toggle
						v-for="activity of module.activities"
						:key="activity.id"
						containerClass="col-3"
						subClass=""
						type="checkbox"
						name="activity_ids[]"
						:label="activity.activity_type.name"
						:value="activity.id"
						:checked="isActivityChecked(activity.id)"
					></app-toggle>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Toggle from '../forms/ToggleComponent';

    export default {
		components: {
			'app-toggle': Toggle,
		},
		data() {
			return {
				checked: false,
			};
		},
        props: {
            module: Object,
			activityIds: Array,
        },
		methods: {
			onselectModuleGroup(value, e) {
				$(e.currentTarget).closest('.card')
					.find('input')
					.prop('checked', value);
			},
			isActivityChecked(id) {
				let checked = this.activityIds.includes(id);

				this.checkAll();

				return checked;
			},
			async checkAll() {
				await Help.delay(100);

				let checkeds = $(this.$refs.activities).find('input')
					.filter('input:checked')
					.length;
				
				this.checked = (checkeds == this.module.activities.length);
			}
		}
    }
</script>