var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-thumb-tack","title":"Relatório de Indicações","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Indicações' },
        ]}}),_vm._v(" "),_c('app-filter',{attrs:{"filters":[
            {
                icon: 'fa fa-calendar',
                name: 'year',
                value: _vm.year,
                type: 'input',
                datepicker: true,
                index: 4,
            }
        ],"onChangeCallback":_vm.onFilter}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            order: [0, 'desc'],
            print: true,
            columnsToPrint: [0,1,2,3],
            ajax: {
                url: '/report/indications'
            },
            columns: _vm.columns,
            searchCols: [
                null,
                null,
                null,
                null,
                { search: _vm.year },
            ],
            ranges: ['range']
        },"actions":{
            student: {
                allow: _vm.allows.students,
                url: '/indications/*/report'
            },
        },"filters":[
            {
                type: 'range',
                name: 'range',
            }
        ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }