var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-book","title":"Livros","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Livros' },
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"books","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            ajax: {
                url: '/web/books'
            },
            columns: _vm.columns
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/books/create'
            },
            show: {
                allow: _vm.allows.show,
                callback: _vm.onShow
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/books/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            },
            preview: {
                allow: _vm.allows.preview,
                callback: _vm.onPreview
            },
            indicate: {
                allow: _vm.allows.indicate,
                url: '/indications/create?activity_id=@',
            },
        }}}),_vm._v(" "),_c('app-book-show',{ref:"bookShow"}),_vm._v(" "),_c('app-book-preview',{ref:"bookPreview"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }