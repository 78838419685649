<template>
    <div class="stage" :class="{ 'on' : isOpened }">
        <div ref="container" id="stage">
            <div v-if="this.data.length" class="book-container">
                <div ref="book" class="book">
                    <div class="page">
                        <div class="cover"></div>
                    </div>
                    <div class="page" v-for="(p, i) in data">
                        <div
                            :style="`background-image: url('${p.image}');`"
                            :class="(i%2) ? 'R' : 'L'"
                        >
                            <a
                                v-if="!(i%2)"
                                href="javascript:" @click="playSound(p.sound)"
                                class="bt-sound"
                            >
                                <img src="/assets/img/book/btPlay.png">
                            </a>
                        </div>
                    </div>
                    <div class="page">
                        <div class="cover"></div>
                    </div>
                </div>
            </div>
        </div>
        <a
            ref="btClose"
            class="bt-close"
            :class="{ 'on' : isOpened }"
            @click="this.destroy"
        >
            <img src="/assets/img/bt_close.png">
        </a>
        <audio ref="audio"></audio>
    </div>
</template>

<script>
    export default {
        data: (e) => {
            return {
                isOpened: false,
                book: null,
                data: [],
            }
        },
        methods: {
            load(id) {
                console.clear();

                this.isOpened = true;

                cash(document.body).css({ "overflow-y": "hidden" });

                $.ajax({
                    url: `/storage/files/books/${id}/data.xml`,
                    dataType: 'xml',
                    success: (res) => {
                        for (let p of res.querySelectorAll('page')) {
                            let name = p.querySelector('name').textContent;
                            let url = `/storage/files/books/${id}/files/${name}`;
                            let data = { image: `${url}.jpg`, sound: `${url}.mp3` };

                            this.data.push(data, data);
                        }
                        
                        this.init();
                    },
                    error: (err) => {
                        this.isOpened = false;

                        Swal.fire({
                            title: 'Ops, visualização indiponível!',
                            text: 'Arquivos não encontrados!',
                            type: 'warning',
                        });
                    }
                });
            },
            async init() {
                let size = this.getSize();
                
                await Help.delay(100);

                this.book = $(this.$refs.book).turn({
                    width: size.width,
                    height: size.height,
                    page: 2,
                });

                $(window).resize(() => {
                    this.setBtPosition();
                });

                await Help.delay(100);

                this.setBtPosition();
            },
            getSize() {
                let [w,h] = [705,450];
                
                if (window.innerWidth < w || window.innerHeight < h) {
                    if (window.innerWidth > window.innerHeight) {
                        w = (window.innerHeight / h) * w;
                        h = window.innerHeight;
                    } else {
                        w = window.innerWidth * 0.5;
                        h = (window.innerWidth / w) * h;
                    }
                }
                
                return { width: w, height: h };
            },
            setBtPosition() {
                const W_WIDTH = cash(window).width();
                const W_HEIGHT = cash(window).height();
                var position = { y: 5, x: W_WIDTH - 65 };

                if (W_WIDTH > 798 && W_HEIGHT > 480) {
                    position = this.$refs.container.getBoundingClientRect();
                    
                    position.y = (W_HEIGHT * 0.5) - 225 - 30;
                    position.x = (W_WIDTH * 0.5) + 399 - 40;
                }

                cash(this.$refs.btClose).css({
                    top: position.top,
                    left: position.left
                });
            },
            playSound(url) {
                let sound = this.$refs.audio;
                sound.src = url;
                sound.play();
                sound.volume = 0.5;
            },
            destroy() {
                if (this.book) {
                    this.book.turn('destroy');
                    this.book = null;
                }
                
                this.isOpened = false;
                this.data = [];
                this.$refs.audio.pause();
                cash(document.body).css({ 'overflow-y': 'unset' });
                console.clear();
            }
        }
    }
</script>