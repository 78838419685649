var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom mb-2"},[_c('div',{class:[
            'card-body p-2 d-flex',
            'justify-content-between',
            'align-items-center'
        ]},[_c('div',[_vm._v(_vm._s(_vm.data.title))]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{class:[
                    'toggle d-flex',
                    'align-items-center'
                ]},[_c('label',[_c('input',{attrs:{"type":"checkbox","name":"id"},domProps:{"value":_vm.data.id,"checked":(_vm.data.active == 1)},on:{"change":_vm.onChange}}),_vm._v(" "),_c('span',{staticClass:"button-indecator"})])]),_vm._v(" "),(_vm.editable)?_c('app-link',{attrs:{"type":"create","icon":"pencil","color":"warning","title":"Editar","data-id":_vm.data.id,"disableTitle":true,"onClick":_vm.onEdit}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }