var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-th-list","title":"Relatório de Módulos","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Módulos' },
        ]}}),_vm._v(" "),_c('app-filter',{attrs:{"filters":[
            {
                icon: 'fa fa-calendar',
                name: 'year',
                value: _vm.year,
                type: 'input',
                datepicker: true,
                index: 3,
            }
        ],"onChangeCallback":_vm.onFilter}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            order: [2, 'desc'],
            print: true,
            btFilter: false,
            ajax: {
                url: '/report/modules'
            },
            columns: _vm.columns,
            searchCols: [
                null,
                null,
                null,
                { search: _vm.year },
            ],
        },"actions":{}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }