<template>
    <div class="stage" :class="{ 'on' : isOpened }">
        <div ref="container" id="stage"></div>
        <a
            ref="btClose"
            class="bt-close"
            :class="{ 'on' : isOpened }"
            @click="this.destroy"
        >
            <img src="/assets/img/bt_close.png">
        </a>
    </div>
</template>

<script>
    export default {
        data: (e) => {
            return {
                isOpened: false,
                id: Number,
                url: String,
                urlDefault: `/storage/files/games/{id}/game.js`,
                game: null,
            }
        },
        methods: {
            load(id) {
                this.id = id;
                this.url = this.urlDefault.replace('{id}', id);
                
                this.addScripts([this.url], this.onReady, this.onLoadError);
            },
            async onReady() {
                console.clear();

                this.isOpened = true;

                $(document.body).css({ "overflow-y": "hidden" });

                this.game = new GamePlayer.Main({
                    callback: (result) => {
                        // console.log(result);
                    },
                });

                $(window).resize(() => {
                    this.setBtPosition();
                });

                await Help.delay(100);

                this.setBtPosition();
            },
            onLoadError(e) {
                Swal.fire({
                    title: 'Ops, visualização indiponível!',
                    text: 'Arquivo não encontrado!',
                    type: 'warning',
                });
            },
            setBtPosition() {
                var position = { y: 5, x: window.innerWidth - 65 };

				if ($(window).width() > 798 && $(window).height() > 480) {
					let bounds = this.$refs['container'].getBoundingClientRect();
					position = { x: bounds.x, y: bounds.y };

					position.y = position.y - 30;
					position.x = position.x + bounds.width - 30;
				}

				$(this.$refs['btClose']).css({
					top: position.y,
					left: position.x
				});
            },
            destroy() {
                if (this.game && this.game.script4) {
                    if (this.game.script4.load && this.game.script4.load.isLoading) {
                        this.game.script4.load.finishedLoading = () => {};
                        this.game.script4.load.reset();
                        this.game.script4.load.removeAll();
                        this.game.script4.destroy(true);
                    } else {
                        if (
                            this.game.script4.stage &&
                            this.game.script4.stage.children[0].children.length
                        ) {
                            this.game.script4.stage.children[0].children[0].destroyAll();
                        }
                    }
                }

                this.game = null;
                this.isOpened = false;
                $(this.$refs['container']).empty();
                $(`script[src*="${this.url}"]`).remove();
            }
        }
    }
</script>