<template>
	<div v-if="director">
		<app-page-header
			icon="fa fa-user"
			:title="'Editar Diretor: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/directors', title: 'Diretores' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb"
                                        class="thumb img-thumbnail img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Inserir Imagem"
                                        :outline="false"
                                        :withTitle="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                    <input
                                        type="hidden"
                                        name="removeThumb"
                                        :value="removeThumb"
                                    >
                                </div>
								<app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome Completo"
                                    placeholder="Nome Completo"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="true"
                                    :value="director.name"
                                ></app-input>
								<app-input
                                    name="user[email]"
                                    label="E-mail"
                                    placeholder="E-mail"
                                    :maxLength="300"
                                    :required="true"
                                    type="email"
                                    :value="director.user.email"
                                ></app-input>
                                <app-input
                                    name="user[login]"
                                    label="Usuário"
                                    placeholder="Usuário"
                                    :maxLength="11"
                                    :minLength="4"
                                    title="apenas letras e números, mínimo 4 caracteres"
                                    :disabled="true"
                                    :value="director.user.login"
                                ></app-input>
								<app-input
                                    title="mínimo 14 caracters"
                                    name="phone"
                                    label="Telefone"
                                    placeholder="Telefone"
                                    mask="(##) ####-####"
                                    :masked="true"
                                    :maxLength="15"
                                    :minLength="14"
                                    :required="true"
                                    :value="director.phone"
                                ></app-input>
                                <app-input
                                    title="mínimo 14 caracters"
                                    name="cell_phone"
                                    label="Celular"
                                    placeholder="Celular"
                                    mask="(##) #####-####"
                                    :maxLength="15"
                                    :minLength="14"
                                    :value="director.cell_phone"
                                ></app-input>
								<app-toggle
                                    v-if="isManager"
									label="Status"
									name="active"
									:value="director.active"
								></app-toggle>
							</div>
							<div class="row">
                                <app-input-tags
                                    ref="schools"
                                    name="schools"
                                    label="Escolas"
                                    urlData="/web/schools/list?toTags=true"
									title="Selecione pelo menos uma escola"
									:fields="{ value: 'value', text: 'label' }"
                                    :values="director.schools"
                                ></app-input-tags>
                            </div>
							<div v-if="changePass" class="col-12">
                                <fieldset>
                                    <legend>Editar Senha</legend>
                                    <div class="row">
                                        <app-input
                                            name="user[password]"
                                            label="Senha"
                                            placeholder="Senha"
                                            :maxLength="10"
                                            :minLength="6"
                                            :required="true"
                                            :disabled="!changePass"
                                            title="mínimo 6 caracters"
                                            type="password"
                                        ></app-input>
                                        <app-input
                                            name="confirm_password"
                                            label="Confirmar Senha"
                                            placeholder="Confirmar Senha"
                                            :maxLength="10"
                                            :minLength="6"
                                            :required="true"
                                            :disabled="!changePass"
                                            title="mínimo 6 caracters"
                                            type="password"
                                        ></app-input>
                                    </div>
                                </fieldset>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                    <app-link
                                        type="edit"
                                        icon="pencil-square-o"
                                        title="Editar Senha"
                                        :onClick="() => { changePass = !changePass }"
                                    ></app-link>
                                </div>
                            </div>
						</form>

						<app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import ImageSelect from '../ImageSelectComponent';
	import InputTags from '../forms/InputTagsComponent';
    import Toggle from '../forms/ToggleComponent';

	export default {
		components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
			'app-input-tags': InputTags,
            'app-toggle': Toggle,
        },
		data() {
			return {
				director: null,
				DEFAULT_IMAGE: '/assets/img/default_thumbs/user.png',
                tempImage: '',
				removeThumb: 0,
                changePass: false,
			}
		},
		props: {
            id: String,
            isManager: Boolean,
            backUrl: String,
        },
		mounted() {
			this.tempImage = this.DEFAULT_IMAGE;
			this.load(this.id);
		},
		methods: {
			load(id) {
                axios.get(`/web/directors/${id}`)
                    .then(res => {
                        this.director = res.data;
                        this.tempImage = this.director.thumb;
                    })
                    .catch(err => {});
            },
            openEditImage() {
                this.$refs.imageSelect.open();
            },
			imageLoaded(image) {
                this.removeThumb = (image != null) ? 0 : 1;

                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
			onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');
                    formData.append('thumb', this.$refs.imageSelect.file);

					for (let tag of this.$refs.schools.selectedData) {
						formData.append('schools[]', tag.value);
					}

                    axios.post(`/web/directors/${this.director.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                if (this.isManager) {
                                    window.location.href = '/directors';
                                } else {
                                    window.location.href = '/';
                                }
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
            }
		}
	}
</script>