<template>
    <div class="row">
        <div class="col-12">
            <div class="tile p-0">
                <div class="tile-body row m-0">
                    <div
                        :class="[
                            'widget-small light mb-0 coloured-icon',
                            'align-self-sm-start col-12 p-0'
                        ]"
                    >
                        <i class="icon fa fa-filter fa-3x py-4 py-md-3"></i>
                        <div class="row d-flex align-items-center w-100 mx-1">
                            <div
                                v-for="filter in filters"
                                v-if="!filter.disabled"
                                :class="filter.class ?? 'col-12 col-xl-3 col-md-6'"
                            >
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label 
                                            class="input-group-text"
                                            data-toggle="popover" 
                                            :data-content="filter.popover_content"
                                        >
                                            <i :class="filter.icon"></i>
                                        </label>
                                    </div>
                                    <input
                                        v-if="filter.type == 'input'"
                                        class="form-control form-control-sm"
                                        :ref="filter.name"
                                        :name="filter.name"
                                        :index="filter.index"
                                        v-model="filter.value"
                                        type="text"
                                        @change="onChange"
                                        :class="{ 'datepicker' : filter.datepicker }"
                                    >
                                    <app-input-toggle
                                        v-if="filter.type == 'toggle'"
                                        :name="filter.name"
                                        :ref="filter.name"
                                        :checked="filter.value"
                                        :callback="onChange"
                                        :full-element="true"
                                        :on-label="'Com alunos transferidos'"
                                        :off-label="'Sem alunos transferidos'"
                                        :off-style="'secondary'"
                                        :size="'small'"
                                    ></app-input-toggle>
                                    <select
                                        v-if="!filter.type"
                                        :class="{ 'selectpicker' : filter.liveSearch }"
                                        class="form-control form-control-sm"
                                        :data-live-search="filter.liveSearch"
                                        :data-live-search-normalize="true"
                                        :data-none-selected-text="'-'"
                                        :ref="filter.name"
                                        :name="filter.name"
                                        :index="filter.index"
                                        v-model="filter.value"
                                        @change="onChange"
                                    >
                                        <option
                                            v-for="data in filter.data"
                                            :value="data.value"
                                            :selected="(filter.value == data.value)"
                                        >
                                            {{ data.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputToggle from './forms/InputToggleComponent.vue';

    export default {
        components: {
            'app-input-toggle': InputToggle,
        },
        props: {
            filters: [],
            onChangeCallback: Function,
        },
        mounted() {
            for (let f of this.filters) {
                if (f.disabled) continue;

                let el = this.$refs[f.name][0];
                el = $(el);
                
                if (f.type == 'input' && el.hasClass('datepicker')) {
                    el.datepicker({
                        autoclose: true,
                        format: 'yyyy',
                        language: 'pt-BR',
                        viewMode: 'years',
                        minViewMode: 'years',
                        orientation: 'bottom',
                    })
                    .change(this.onChange)
                    .on('show', (e) => {
                        let target = $(e.currentTarget).parent();
                        let y = target.offset().top + target.height();
                        
                        $('.datepicker-dropdown').offset({ top: y });
                    });

                    el.trigger('change');
                } else {
                    this.onChange({ target: el[0] });
                }
            }
        },
        methods: {
            onChange(e) {
                let o = $(e.target);
                let name = o.attr('name');
                let val = o.val();

                if (o.hasClass('datepicker')) {                    
                    this.filters.map((filter) => {
                        if (filter.name == name) {
                            filter.value = val;
                        }
                    });
                }
                
                let data = [];
                
                if (name == 'toggle-student-active') {
                    data.push({ name: name, value: e.currentTarget.checked}); 
                }
                
				for (let filter of this.filters) {
					
                    data.push({ name: filter.name, value: filter.value });

					if (filter.callback && filter.value && name == filter.name) {
						filter.callback(filter);
					}
				}
                
                this.onChangeCallback(data);
            }
        }
    }
</script>