<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Pais ou Responsáveis"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Pais ou Responsáveis' }
            ]"
        ></app-page-header>

        <app-datatable
            entity="responsibles"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="4"
            :options="{
                ajax: {
                    url: '/web/responsibles'
                },
                columns: columns
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/responsibles/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: allows.edit,
                    url: '/responsibles/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                }
            }"
            :filters="[
                {
                    name: 'cpf',
                    type: 'input',
                    label: 'CPF',
                    index: 5,
                }
            ]"
        ></app-datatable>

        <app-responsible-show ref="responsibleShow"></app-responsible-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import DataTable from '../DataTableComponent';
    import ResponsibleShow from './ResponsibleShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-responsible-show': ResponsibleShow,
        },
        props: {
            allows: Object,
            columns: Array,
            params: Object | Array,
        },
        mounted() {
            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
        methods: {
            onShow(id) {
                this.$refs.responsibleShow.open(id);
            },
        }
    }
</script>