<template>
    <div>
        <app-page-header
			icon="fa fa-question"
			title="Cadastrar Pergunta"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/faqs', title: 'Faqs' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
                                <app-input
									name="question"
                                    title="mínimo 6 caracteres"
                                    label="Pergunta"
                                    placeholder="Pergunta"
                                    :maxLength="150"
                                    :minLength="6"
                                    :required="true"
                                ></app-input>
                                <app-textarea
                                    ref="editor"
                                    name="answer"
                                    title="mínimo 6 caracteres"
                                    label="Resposta"
                                    placeholder="Resposta"
                                    :required="true"
                                    :minLength="6"
                                    :maxLength="150"
                                    resize="none"
                                    containerClass="col-12"
                                    :with-editor="true"
                                ></app-textarea>
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header">Disponível em quais perfis?</div>
                                        <div class="card-body row">
                                            <app-checkbox
                                                v-for="profile in profiles"
                                                :key="profile.id"
                                                placeholder="Perfil"
                                                name="profiles[]"
                                                :label="profile.title"
                                                :value="profile.id"
                                                :required="true"
                                            ></app-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Textarea from '../forms/TextareaComponent';
    import Checkbox from '../forms/CheckboxComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-textarea': Textarea,
            'app-checkbox': Checkbox,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
            return {
                profiles: [],
            }
        },
        props: {
			backUrl: String,
		},
        mounted() {
            this.loadProfiles();
        },
        methods: {
            loadProfiles() {
                axios.get('/web/profiles/list')
                    .then(res => {
                        this.profiles = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);

                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/faqs`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/faqs';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>