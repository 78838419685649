var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.profile)?_c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-cog","title":'Editar Perfil: ' + _vm.profile.id,"breadcrumbs":[
				{ href: '/', title: 'Painel' },
				{ href: '/profiles', title: 'Perfis' },
				{ href: '', title: 'Editar' },
			]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tite-body"},[_c('form',{ref:"form"},[_c('input',{attrs:{"type":"hidden","name":"group"},domProps:{"value":_vm.profile.group}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-input',{attrs:{"placeholder":"Título","label":"Título","name":"title","minLength":2,"maxLength":50,"required":true,"value":_vm.profile.title}}),_vm._v(" "),_c('app-input',{attrs:{"placeholder":"Código","label":"Código","name":"code","minLength":2,"maxLength":50,"required":true,"value":_vm.profile.code}}),_vm._v(" "),_c('app-toggle',{attrs:{"label":"Status","name":"active","value":Boolean(_vm.profile.active)}})],1),_vm._v(" "),_c('h5',{staticClass:"h5"},[_vm._v("Permissões")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"accordion col-12",attrs:{"id":"accordion"}},[_c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"collapse show",attrs:{"id":"menu","aria-labelledby":"headingMenu","data-parent":"#accordion"}},[(_vm.menus)?_c('div',{staticClass:"card-body"},[_c('app-checkbox-group',{attrs:{"name":"menus","list":_vm.menus,"checkeds":_vm.profile.menus}})],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"card"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"collapse",attrs:{"id":"resources","aria-labelledby":"headingResources","data-parent":"#accordion"}},[(_vm.resources)?_c('div',{staticClass:"card-body"},[_c('app-checkbox-group',{attrs:{"name":"resources","list":_vm.resources,"checkeds":_vm.profile.resources}})],1):_vm._e()])])])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('app-button',{attrs:{"type":"update","callback":_vm.onSubmit}}),_vm._v(" "),_c('app-link',{attrs:{"type":"back","url":_vm.backUrl}})],1)])])])])])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header",attrs:{"id":"headingMenu"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button","data-toggle":"collapse","data-target":"#menu","aria-expanded":"true","aria-controls":"menu"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\tMenu\n\t\t\t\t\t\t\t\t\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header",attrs:{"id":"headingResources"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button","data-toggle":"collapse","data-target":"#resources","aria-expanded":"false","aria-controls":"resources"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\tRecursos\n\t\t\t\t\t\t\t\t\t\t\t")])])
}]

export { render, staticRenderFns }