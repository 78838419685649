<template>
	<div>
		<app-page-header
			icon="fa fa-male"
			title="Avatar"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '', title: 'Avatar' },
			]"
		></app-page-header>

		<div class="tile">
			<div class="alert alert-warning" v-if="!allowPreview || !fileExist">
                <i class="fa fa-exclamation-triangle"></i>
                Visualização indisponível, entre em contato com o administrador!
            </div>

			<app-link
				type="info"
				title="Pre-visualizar"
				icon="eye"
				:onClick="preview"
				:disabled="!fileExist || !allowPreview"
			></app-link>

			<div class="stage" :class="{ 'on' : isOpened }">
				<div ref="container" id="stage"></div>
				<a
					ref="btClose"
					class="bt-close"
					:class="{ 'on' : isOpened }"
					@click="this.destroy"
				>
					<img src="/assets/img/bt_close.png" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-link': Link,
		},
		props: {
			allowPreview: Boolean
		},
		data() {
			return {
				isOpened: false,
				fileExist: false,
				avatar: null,
			};
		},
		mounted() {
			this.addScripts(['/storage/files/avatar/avatar.js'], this.onReady);
		},
		methods: {
			onReady() {
				this.fileExist = true;
			},
			async preview() {
				console.clear();

				this.isOpened = true;

				$(document.body).css({ 'overflow-y': 'hidden' });

				this.avatar = new AvatarPlayer.Main({
					baseUrl: '',
					preview: true,
				});

				$(window).resize(() => {
					this.setBtPosition();
				});

				await Help.delay(100);

				this.setBtPosition();
			},
			setBtPosition() {
				var position = { y: 5, x: window.innerWidth - 65 };

				if ($(window).width() > 798 && $(window).height() > 480) {
					let bounds = this.$refs['container'].getBoundingClientRect();
					position = { x: bounds.x, y: bounds.y };

					position.y = position.y - 30;
					position.x = position.x + bounds.width - 30;
				}

				$(this.$refs['btClose']).css({
					top: position.y,
					left: position.x
				});
			},
			destroy() {
				if (this.avatar && this.avatar.script4) {
					if (this.avatar.script4.load && this.avatar.script4.load.isLoading) {
						this.avatar.script4.load.finishedLoading = () => { };
						this.avatar.script4.load.reset();
						this.avatar.script4.load.removeAll();
						this.avatar.script4.destroy(true);
					} else {
						if (
							this.avatar.script4.stage &&
							this.avatar.script4.stage.children[0].children.length
						) {
							this.avatar.script4.stage.children[0].children[0].destroyAll();
						} else {
							this.avatar.script4.destroy(true);
						}
					}
				}

				this.avatar = null;
				this.isOpened = false;
				$(this.$refs['container']).empty();
				$(document.body).css({ 'overflow-y': 'unset' });
				console.clear();
			},
		},
	};
</script>