var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"title":"Escolas","icon":"fa fa-university","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Escolas' },
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"schools","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            ajax: {
                url: '/web/schools',
            },
            columns: _vm.columns,
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/schools/create',
            },
            show: {
                allow: _vm.allows.show,
                callback: _vm.onShow,
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/schools/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*',
            },
            classrooms: {
                allow: true,
                url: '/classrooms?school_id=*'
            },
        },"filters":[
            {
                name: 'director_name',
                type: 'input',
                input: 'text', 
                label: 'Nome do Diretor',
                index: 5,
            },
            {
                name: 'state_id',
                type: 'select',
                label: 'Estado',
                options: _vm.states,
                liveSearch: true,
                callback: _vm.onChangeState,
            },
            {
                name: 'city_id',
                type: 'select',
                label: '-',
                index: 4,
                liveSearch: true,
                options: [],
            }
        ]}}),_vm._v(" "),_c('app-school-show',{ref:"schoolShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }