<template>
	<div v-if="classroom">
		<app-page-header
            icon="fa fa-graduation-cap"
            :title="'Editar Sala: ' + id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/classrooms', title: 'Salas' },
                { href: '', title: 'Editar' }
            ]"
        ></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<app-input
                                    title="mínimo 4 caracters"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="65"
                                    :minLength="4"
                                    :required="true"
									:value="classroom.name"
                                ></app-input>
								<app-select
									v-if="!isDirector"
									name="school_id"
									label="Escola"
									title="Escola"
									:required="true"
									:options="schools"
									:data-live-search="true"
									empty="false"
									:value="dataTemp.school"
								></app-select>
								<app-select
									name="year_id"
									label="Ano"
									title="Ano"
									:required="true"
									:options="years"
									:value="dataTemp.year"
								></app-select>
								<app-toggle
									label="Status"
									name="active"
									:value="classroom.active"
								></app-toggle>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
	import Toggle from '../forms/ToggleComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
			'app-toggle': Toggle,
		},
		data() {
			return {
				classroom: null,
				schools: [],
				years: [],
				dataTemp: {
					school: '',
					year: ''
				}
			}
		},
		props: {
			id: String,
			isDirector: Boolean,
			backUrl: String,
		},
		mounted() {
			this.load(this.id);
		},
		methods: {
			loadSchools() {
				axios.get('/web/schools/list?toTags=true')
					.then(async res => {
						this.schools = res.data;
						this.dataTemp.school = this.classroom.school_id;
					})
					.catch(err => {});
			},
			loadYears() {
				axios.get('/web/years/list')
					.then(res => {
						this.years = res.data;
						this.dataTemp.year = this.classroom.year_id;
					})
					.catch(err => {});
			},
			load(id) {
				axios.get(`/web/classrooms/${id}`)
					.then(res => {
						this.classroom = res.data;

						this.loadSchools();
						this.loadYears();
					})
					.catch(err => {});
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);

				formData.append('_method', 'PATCH');

				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					Flash('error', validate);
				} else {
					axios.post(`/web/classrooms/${this.classroom.id}`, formData)
						.then(res => {
							Swal.fire({
								title: 'Atualizado com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/classrooms';
							});
						})
						.catch(err => {
							Flash('error', 'Erro ao tentar atualizar registro!');
						});
				}
			}
		}
	}
</script>