<template>
    <div>
        <input
            ref="toggle"
            :name="name"
            type="checkbox"
            data-toggle="toggle"
            :data-onstyle="onStyle"
            :data-offstyle="offStyle"
            :data-on="onLabel"
            :data-off="offLabel"
            :checked="checked"
            @change="onChange()"
            :data-size="size"
        >
    </div>
</template>

<script>
    export default {
        props: {
            name: String,
            onStyle: { type: String, default: 'primary' },
            offStyle: { type: String, default: 'primary' },
            onLabel: { type: String, default: 'on' },
            offLabel: { type: String, default: 'off' },
            checked: { type: Boolean, default: false },
            size: {type: String, default: "normal"},
            callback: Function,
            fullElement: {type: Boolean, default: false},
        },
        mounted() {
            $(this.$refs.toggle).change(this.onChange);
        },
        methods: {
            onChange(e) {
                let val = $(e.currentTarget).prop('checked');

                if (this.callback && !this.fullElement) {
                    this.callback(val);
                }
                
                if (this.callback && this.fullElement) {
                    this.callback(e);
                }
            }
        }
    }
</script>