var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-xl",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_c('i',{staticClass:"fa fa-book"}),_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.title)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.onClose}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row content"},[(_vm.loading)?_c('div',{staticClass:"col-12 text-center"},[_c('img',{attrs:{"src":"/assets/img/loading.svg"}})]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-3 col-md-6 mb-3"},[_c('img',{staticClass:"img-rounded img-fluid",attrs:{"src":_vm.data.thumb}})]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(0),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.title))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.subject))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(2),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.active_text))])]),_vm._v(" "),(!_vm.data.have_files)?_c('div',{staticClass:"col-md-12"},[_vm._m(3)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(4),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.description))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(5),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.tags))])])])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('app-link',{attrs:{"type":"back","onClick":_vm.goBack}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Título")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Assunto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Status")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v("\n                                        Necessário subir arquivos do livro!\n                                    ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Descrição")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Tags")])])
}]

export { render, staticRenderFns }