<template>
    <div>
        <app-page-header
            icon="fa fa-ticket"
            title="Tickets"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Tickets' }
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            entity="tickets"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :options="{
                ajax: {
                    url: '/web/tickets'
                },
                columns: columns
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/tickets/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                reopen: {
                    allow: true,
                    callback: onReopen,
                },
                close: {
                    allow: true,
                    callback: onClose,
                }
            }"
            :filters="[
                {
                    name: 'department',
                    type: 'select',
                    label: 'Departamento',
                    index: 3,
                    options: departments
                },
                {
                    name: 'status',
                    type: 'select',
                    label: 'Status',
                    index: 5,
                    options: status
                }
            ]"
        ></app-datatable>

        <app-ticket-show
            ref="ticketShow"
            :profile-id="profileId"
            :user-logged-id="userLoggedId"
            :onTransfer="onTransfer"
            :onReopen="onReopen"
            :onClose="onClose"
        ></app-ticket-show>

        <app-ticket-create-message
            ref="ticketCreateMessage"
            :onSent="reloadTable"
        ></app-ticket-create-message>

        <app-ticket-transfer
            ref="ticketTransfer"
            :onSent="reloadTable"
        ></app-ticket-transfer>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';
    import TicketShow from './TicketShowComponent';
    import TicketCreateMessage from './TicketCreateMenssageComponent';
    import TicketTransfer from './TicketTransferComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
            'app-ticket-show': TicketShow,
            'app-ticket-create-message': TicketCreateMessage,
            'app-ticket-transfer': TicketTransfer,
        },
        data() {
            return {
                departments: [],
                status: [],
            }
        },
        props: {
            profileId: Number,
            userLoggedId: Number,
            columns: Array,
            allows: Object,
        },
        mounted() {
            this.loadDepartments();
            this.loadStatus();
        },
        methods: {
            loadDepartments() {
                axios.get('/web/tickets/departments')
                    .then(res => {
                        this.departments = res.data;
                    })
                    .catch(err => {});
            },
            loadStatus() {
                axios.get('/web/tickets/status')
                    .then(res => {
                        this.status = res.data;
                    })
                    .catch(err => {});
            },
            onShow(id) {
                this.$refs.ticketShow.open(id);
            },
            onTransfer(id) {
                this.$refs.ticketTransfer.open(id);
            },
            onReopen(id) {
                this.$refs.ticketCreateMessage.open(id);
            },
            onClose(id) {
                Swal.fire({
                    title: 'Fechar Ticket',
                    text: 'Deseja fechar esse ticket?',
                    type: 'question',
                    confirmButtonText: 'Confirmar',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                }).then(res => {
                    if (res.value) {
                        let formData = new FormData();
                        formData.append('_method', 'PATCH');

                        axios.post(`/web/tickets/${id}/close`, formData)
                            .then(res => {
                                Swal.fire({
                                    title: 'Ticket fechado com sucesso!',
                                    type: 'success'
                                })
                                .then(() => {
                                    this.$refs.dataTable.reload();
                                });
                            })
                            .catch(err => {
                                Flash('error', 'Erro ao tentar fechar ticket!');
                            });
                    }
                });
            },
            reloadTable() {
                this.$refs.dataTable.reload();
            }
        }
    }
</script>