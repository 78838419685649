var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-6 p-2"},[(_vm.activity)?_c('div',{class:[
            'card card-light card-activity',
            _vm.activity.activity_type.code
        ]},[_c('div',{class:[
                'card-header d-flex justify-content-between p-2',
                _vm.activity.activity_type.code + '-color'
            ],attrs:{"data-toggle":"tooltip","data-placement":"top","title":_vm.activity.title_full}},[_c('i',{class:'fa fa-2x fa-' + _vm.icon}),_vm._v(" "),_c('div',_vm._l((_vm.stars),function(star){return _c('img',{class:star,attrs:{"src":"/assets/img/icon_star.png"}})}),0)]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text text-center text-truncate px-2",attrs:{"title":_vm.activity.type.title,"data-toggle":"tooltip"}},[_vm._v("\n                "+_vm._s(_vm.activity.type.title)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"card-footer d-flex justify-content-between align-items-center p-2"},[_c('span',[_vm._v(_vm._s(_vm.activity.activity_type.text)+" "+_vm._s(_vm.activity.scores_count)+"x")]),_vm._v(" "),_c('button',{class:[
                    'btn btn-outline-info btn-circle',
                    'btn-circle-sm btn-table'
                ],attrs:{"title":"Histórico","data-toggle":"tooltip"},on:{"click":function($event){return _vm.openChart(_vm.activity.id)}}},[_c('i',{staticClass:"fa fa-line-chart"})])])]):_c('div',{staticClass:"card card-activity ghost"},[_c('div',{staticClass:"card-header",style:({ height: '47px' })},[_vm._v(" ")]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_vm._v(" ")]),_vm._v(" "),_c('div',{staticClass:"card-footer",style:({ height: '52px' })},[_vm._v(" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }