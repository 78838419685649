<template>
    <div v-if="tag">
        <app-page-header
			icon="fa fa-tag"
			:title="'Editar Tag: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/tags', title: 'Tags' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
                                <app-input
                                    title="mínimo 2 caracters"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="40"
                                    :minLength="2"
                                    :required="true"
                                    :value="tag.title"
                                ></app-input>
                                <app-toggle
									label="Status"
									name="active"
									:value="tag.active"
								></app-toggle>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
        },
        data() {
            return {
                tag: null,
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.load(this.id);
        },
        methods: {
            load(id) {
                axios.get(`/web/tags/${id}`)
                    .then(res => {
                        this.tag = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/tags/${this.tag.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/tags';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>