<template>
    <div>
        <app-page-header
            icon="fa fa-th-list"
            title="Relatório de Atividades"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Relatório de Atividades' },
            ]"
        ></app-page-header>

        <app-filter
            v-if="filterReady"
            :filters="[
                {
                    icon: 'fa fa-calendar',
                    name: 'year',
                    value: year,
                    type: 'input',
                    datepicker: true,
                    index: 4,
                },
                {
                    icon: 'fa fa-th-list',
                    name: 'activity_type_id',
                    data: activityTypes,
                    value: activityType,
                    index: 5,
                }
            ]"
            :onChangeCallback="onFilter"
        ></app-filter>

        <app-datatable
            v-if="filterReady"
            ref="dataTable"
            :options="{
                order: [3, 'desc'],
                print: true,
                btFilter: false,
                ajax: {
                    url: '/report/activities'
                },
                columns: columns,
                searchCols: [
                    null,
                    null,
                    null,
                    null,
                    { search: this.year },
                    { search: this.activityType },
                ],
            }"
            :actions="{}"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-filter': Filter,
			'app-datatable': DataTable,
        },
        data() {
            return {
                year: new Date().getFullYear(),
                activityType: '',
                filterReady: false,
            };
        },
        props: {
            columns: Array,
        },
        mounted() {
            this.loadActivityTypes();
        },
        methods: {
            loadActivityTypes()
            {
                axios.get('/web/activity_types/list?toTags=true')
                    .then(res => {
                        this.activityTypes = [{ label: 'Todos os tipos', value: '' }];
                        this.activityTypes.push(...res.data);
                        this.activityType = this.activityTypes[0].value;
                        this.filterReady = true;
                    })
                    .catch(err => {});
            },
            onFilter(data) {
                this.year = data.filter(o => o.name == 'year')[0].value;
                this.activityType = data.filter(o => o.name == 'activity_type_id')[0].value;
                
                this.$refs.dataTable.onChangeSideFilter(data);
            }
        }
    }
</script>