<template>
    <div
		:id="containerId"
		:class="containerClass"
	>
        <div class="animated-checkbox checkbox-xs-2">
            <label>
                <input 
                    type="checkbox" 
                    :name="name"
                    :value="value"
                    :required="required"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :checked="checked"
                    @change="onChange"
                >
                <span
                    class="label-text"
                    :class="(validated != null) ? (validated ? 'is-valid' : 'is-invalid') : ''"
                >
                    {{ label }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            placeholder: String,
            label: String,
            name: String,
            value: String | Number,
            checked: Boolean,
            required: Boolean,
            disabled: { type: Boolean, default: false },
            containerId: String,
			containerClass: { type: String, default: 'col-12 col-xl-4 col-md-6' },
            validated: Boolean | null,
            callback: Function,
        },
        methods: {
            onChange(e) {
                if (this.callback) {
                    this.callback(e);
                }
            }
        }
    }
</script>