<template>
	<div>
		<app-page-header
			icon="fa fa-graduation-cap"
			title="Cadastrar Sala"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/schools', title: 'Salas' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<app-input
                                    title="mínimo 4 caracters"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="65"
                                    :minLength="4"
                                    :required="true"
                                ></app-input>
								<app-select
									name="school_id"
									label="Escola"
									title="Escola"
									:required="true"
									:options="schools"
									:data-live-search="true"
									empty="false"
								></app-select>
								<app-select
									name="year_id"
									label="Ano"
									title="Ano"
									:required="true"
									:options="years"
								></app-select>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent.vue';
    import Input from '../forms/InputComponent.vue';
    import Select from '../forms/SelectComponent.vue';
    import Button from '../forms/ButtonComponent.vue';
    import Link from '../forms/LinkComponent.vue';

	export default {
		components: {
			'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
		},
		data() {
			return {
				schools: [],
				years: [],
			}
		},
		props: {
			backUrl: String,
		},
		mounted() {
			this.loadSchools();
			this.loadYears();
		},
		methods: {
			loadSchools() {
				axios.get('/web/schools/list?toTags=true')
					.then(res => {
						this.schools = res.data;
					})
					.catch(err => {});
			},
			loadYears() {
				axios.get('/web/years/list')
					.then(res => {
						this.years = res.data;
					})
					.catch(err => {});
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/classrooms`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/classrooms';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
			}
		}
	}
</script>