var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-ticket","title":"Tickets","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Tickets' }
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"tickets","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"options":{
            ajax: {
                url: '/web/tickets'
            },
            columns: _vm.columns
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/tickets/create'
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            reopen: {
                allow: true,
                callback: _vm.onReopen,
            },
            close: {
                allow: true,
                callback: _vm.onClose,
            }
        },"filters":[
            {
                name: 'department',
                type: 'select',
                label: 'Departamento',
                index: 3,
                options: _vm.departments
            },
            {
                name: 'status',
                type: 'select',
                label: 'Status',
                index: 5,
                options: _vm.status
            }
        ]}}),_vm._v(" "),_c('app-ticket-show',{ref:"ticketShow",attrs:{"profile-id":_vm.profileId,"user-logged-id":_vm.userLoggedId,"onTransfer":_vm.onTransfer,"onReopen":_vm.onReopen,"onClose":_vm.onClose}}),_vm._v(" "),_c('app-ticket-create-message',{ref:"ticketCreateMessage",attrs:{"onSent":_vm.reloadTable}}),_vm._v(" "),_c('app-ticket-transfer',{ref:"ticketTransfer",attrs:{"onSent":_vm.reloadTable}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }