<template>
    <div>
        <app-page-header
            icon="fa fa-paw"
            title="Pet"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Pet' },
            ]"
        ></app-page-header>

        <div class="tile">
            <div class="alert alert-warning" v-if="!fileExist || !allowPreview">
                <i class="fa fa-exclamation-triangle"></i>
                Visualização indisponível, entre em contato com o administrador!
            </div>

            <app-link
                type="info"
                title="Pré-visualizar"
                icon="eye"
                :onClick="preview"
                :disabled="!fileExist || !allowPreview"
            ></app-link>

            <div class="stage" :class="{ 'on' : isOpened }">
                <div ref="container" id="stagePet"></div>
                <a
                    ref="btClose"
                    class="bt-close"
                    :class="{ 'on' : isOpened }"
                    @click="this.destroy"
                >
                    <img src="/assets/img/bt_close.png">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-link': Link,
        },
        props: {
            allowPreview: Boolean
        },
        data: (e) => {
            return {
                isOpened: false,
                fileExist: false,
                player: null,
            }
        },
        mounted() {
            this.addScripts(['/storage/files/pet/pet.js'], this.onReady);
        },
        methods: {
            onReady() {
                this.fileExist = true;
            },
            async preview() {
                console.clear();

                this.isOpened = true;

                $(document.body).css({ 'overflow-y': 'hidden' });

                this.player = new PetPlayer.Main({
                    student: { id: 0 },
                    baseUrl: '',
                    preview: true,
                    callIndication: () => {}
                });                

                $(window).resize(() => {
                    this.setBtPosition();
                });

                await Help.delay(100);

                this.setBtPosition();
            },
            setBtPosition() {
                var position = { y: 5, x: window.innerWidth - 65 };

				if ($(window).width() > 798 && $(window).height() > 480) {
					let bounds = this.$refs['container'].getBoundingClientRect();
					position = { x: bounds.x, y: bounds.y };

					position.y = position.y - 30;
					position.x = position.x + bounds.width - 30;
				}

				$(this.$refs['btClose']).css({
					top: position.y,
					left: position.x
				});
            },
            destroy() {
                if (this.player && this.player.script4) {
                    if (this.player.script4.load && this.player.script4.load.isLoading) {
                        this.player.script4.load.finishedLoading = () => {};
                        this.player.script4.load.reset();
                        this.player.script4.load.removeAll();
                        this.player.script4.destroy(true);
                    } else {
                        if (this.player.script4.stage &&
                                this.player.script4.stage.children[0].children.length) {
                            this.player.script4.stage.children[0].children[0].destroy();
                        } else {
                            this.player.script4.destroy(true);
                        }
                    }
                }

                this.player = null;
                this.isOpened = false;
                $(this.$refs['container']).empty();
                $(document.body).css({ 'overflow-y': 'unset' });
                console.clear();
            }
        }
    }
</script>