var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-calendar-check-o","title":"Celebrações","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Celebrações' },
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"celebrations","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            ajax: {
                url: '/web/celebrations'
            },
            columns: _vm.columns
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/celebrations/create'
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/celebrations/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            }
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }