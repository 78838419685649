var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-dashboard","title":"Painel","breadcrumbs":[
            { href: '', title: 'Painel' },
        ]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-jumbotron',{attrs:{"name":'Prof. ' + _vm.userName}})],1),_vm._v(" "),(_vm.filters.length && _vm.filters[0].data.length)?_c('app-filter',{attrs:{"filters":_vm.filters,"onChangeCallback":_vm.onFilter}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-widget',{attrs:{"type":"success","icon":"fa fa-graduation-cap","title":"Salas","value":_vm.classroomsCount,"link":{
                label: 'Desempenho',
                url: '/classrooms/report'
            }}}),_vm._v(" "),_c('app-widget',{attrs:{"type":"info","icon":"fa fa-user","title":"Alunos","value":_vm.studentsCount,"link":{
                label: 'Desempenho',
                url: '/students/report'
            }}}),_vm._v(" "),_c('app-widget',{attrs:{"type":"warning","icon":"fa fa-thumb-tack","title":"Indicações concluídas","value":_vm.indicationsCompleted,"link":{
                label: 'Histórico',
                url: '/indications/report'
            }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }