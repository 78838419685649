<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <i class="fa fa-line-chart"></i> 
                        Últimas Pontuações: 
                        <i :class="'fa fa-' + icon"></i> 
                        {{ title }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="loading" class="row">
                        <div class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                    </div>
                    <div
                        class="embed-responsive-16by9"
                        :class="{ 'd-none': loading }"
                        :style="{ minHeight: '250px' }"
                    >
                        <canvas
                            ref="chart"
                            class="chart embed-responsive-item"
                        ></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modal: HTMLElement,
                chart: Chart | null,
                title: '',
                icon: '',
                loading: true,
            };
        },
        props: {
            studentId: Number,
        },
        methods: {
            load(id) {
                if (this.chart) { this.chart.destroy(); }

                this.loading = true;
                this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
                this.icon = '';
				this.modal.modal('show');

                axios.get(`/report/activities/${id}/scores?student_id=${this.studentId}`)
                    .then(res => {
                        this.loading = false;
                        this.show(res.data);
                    })
                    .catch(err => {});
            },
            getParamValues(param, data) {
                let r = [];
                for (let d of data) { r.push(d[param]); }
                return r;
            },
            show(data) {
                const COLORS = [
                    'rgba(18, 125, 205, 0.6)',//GAME
                    'rgba(187, 0, 115, 0.6)',//ANIMATION
                    'rgba(23, 183, 36, 0.6)',//SONG
                    'rgba(224, 57, 57, 0.6)'//BOOK
                ];

                let ctx = this.$refs.chart.getContext('2d');
                
                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.getParamValues('title', data.data),
                        datasets: [{
                            label: 'Desempenho',
                            data: this.getParamValues('value', data.data),
                            backgroundColor: COLORS[data.activity_type.id - 1],
                            borderWidth: 3
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    max: 100,
                                    beginAtZero: true,
                                    callback: (value) => {
                                        return value + '%';
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return data
                                        .datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index] + '%';
                                }
                            }
                        }
                    }
                });
                
                this.title = data.title;
                this.icon = config.getIcon(data.activity_type.code);
            }
        }
    }
</script>