var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-th-list","title":"Relatório de Atividades","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Atividades' },
        ]}}),_vm._v(" "),(_vm.filterReady)?_c('app-filter',{attrs:{"filters":[
            {
                icon: 'fa fa-calendar',
                name: 'year',
                value: _vm.year,
                type: 'input',
                datepicker: true,
                index: 4,
            },
            {
                icon: 'fa fa-th-list',
                name: 'activity_type_id',
                data: _vm.activityTypes,
                value: _vm.activityType,
                index: 5,
            }
        ],"onChangeCallback":_vm.onFilter}}):_vm._e(),_vm._v(" "),(_vm.filterReady)?_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            order: [3, 'desc'],
            print: true,
            btFilter: false,
            ajax: {
                url: '/report/activities'
            },
            columns: _vm.columns,
            searchCols: [
                null,
                null,
                null,
                null,
                { search: this.year },
                { search: this.activityType },
            ],
        },"actions":{}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }