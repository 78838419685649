<template>
	<div>
		<app-page-header
			icon="fa fa-calendar-check-o"
			title="Cadastrar Celebração"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/celebrations', title: 'Celebrações' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>
		
		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<app-input
                                    title="mínimo 4 caracters, letras minúsculas"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="65"
                                    :minLength="4"
                                    :required="true"
									pattern="[a-z]"
                                ></app-input>
								<app-input
                                    title="mínimo 4 caracters"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="65"
                                    :minLength="4"
                                    :required="true"
                                ></app-input>
								<app-input-date-range
									id="date-range"
									label="Duração"
									text-start="De"
									text-end="Até"
									name-start="start_date"
									name-end="finish_date"
									format="dd/mm"
									startDate="01/01"
									endDate="31/12"
									:required="true"
								></app-input-date-range>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Input from '../forms/InputComponent';
	import InputDateRange from '../forms/InputDateRangeComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
            'app-input': Input,
            'app-input-date-range': InputDateRange,
            'app-button': Button,
            'app-link': Link,
		},
		props: {
			backUrl: String,
		},
		methods: {
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/celebrations`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/celebrations';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
			}
		}
	}
</script>