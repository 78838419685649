<template>
    <div>
        <app-page-header
            icon="fa fa-question"
            title="Perguntas Frequentes"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Faqs' }
            ]"
        ></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <div class="row">
                            <app-input
                                name="search"
                                placeholder="Pesquisar"
                                containerClass="col-12 col-xl-4 col-md-6 offset-xl-8 offset-md-6"
                                :callback="load"
                            ></app-input>
                        </div>
                        <hr />
                        <div v-if="!faqs.length" class="row">
                            <div class="col-12 text-muted text-center">
                                Nenhum registro encontrado!
                            </div>
                        </div>
                        <div class="accordion" id="accordion">
                            <div v-for="(faq, i) in faqs" class="card">
                                <a
                                    href="javascript:"
                                    class="card-header text-decoration-none"
                                    data-toggle="collapse"
                                    :data-target="'#collapse' + i"
                                    :id="'heading' + i"
                                >
                                    <div class="row">
                                        <h5
                                            :class="[
                                                'h6 text-truncate col-10 mb-0',
                                                'd-flex align-items-center'
                                            ]"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            :title="faq.question"
                                        >
                                            {{ faq.question }}
                                        </h5>
                                        <div class="col-2 text-right">
                                            <div
                                                href="javascript:"
                                                data-toggle="collapse"
                                                :data-target="'#collapse' + i"
                                                :class="[
                                                    'btn btn-outline-success',
                                                    'btn-circle btn-circle-sm'
                                                ]"
                                            >
                                                <i class="fa fa-plus"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div
                                    class="collapse"
                                    :id="'collapse' + i"
                                    data-parent="#accordion"
                                >
                                    <div class="card-body">
                                        <div v-html="faq.answer"></div>
                                    </div>
                                    <div class="card-footer d-flex justify-content-between">
                                        <div>
                                            <a
                                                href="javascript:"
                                                class="like"
                                                :class="{ 'liked' : faq.liked }"
                                                @click="like(faq)"
                                            >
                                                <i class="fa fa-thumbs-up"></i>
                                            </a>
                                            <span class="text-muted">{{ faq.likes }}</span>
                                            <a
                                                href="javascript:"
                                                class="like"
                                                :class="{ 'disliked' : faq.disliked }"
                                                @click="dislike(faq)"
                                            >
                                                <i class="fa fa-thumbs-down"></i>
                                            </a>
                                            <span class="text-muted">{{ faq.dislikes }}</span>
                                        </div>
                                        <div>
                                            <app-link
                                                v-if="allows.show"
                                                icon="eye"
                                                color="info"
                                                title="Visualizar"
                                                :data-id="faq.id"
                                                :onClick="onShow"
                                            ></app-link>
                                            <app-link
                                                v-if="allows.edit"
                                                icon="pencil"
                                                color="warning"
                                                title="Editar"
                                                :data-id="faq.id"
                                                :onClick="onEdit"
                                            ></app-link>
                                            <app-link
                                                v-if="allows.delete"
                                                icon="trash"
                                                color="danger"
                                                title="Deletar"
                                                :data-id="faq.id"
                                                :onClick="onDelete"
                                            ></app-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-link
			v-if="allows.create"
			type="create"
			url="/faqs/create"
		></app-link>

        <app-faq-show ref="faqShow"></app-faq-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import FaqShow from './FaqShowComponent';
    import Input from '../forms/InputComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-faq-show': FaqShow,
            'app-input': Input,
            'app-link': Link,
		},
        data() {
            return {
                faqs: [],
            }
        },
        props: {
            allows: Object,
        },
        mounted() {
            this.load();
        },
        methods: {
            load(search = null) {
                let params = (search) ? `?search=${search}` : '';

                axios.get(`/web/faqs${params}`)
                    .then(res => {
                        this.faqs = res.data;
                    })
                    .catch(err => {});
            },
            like(faq) {
                axios.patch(`/web/faqs/${faq.id}/like`)
                    .then(res => {
                        if (faq.disliked) {
                            faq.disliked = false;
                            faq.dislikes -= 1;
                        }

                        faq.liked = !faq.liked;
                        faq.likes += (faq.liked) ? 1 : -1;
                    })
                    .catch(err => {});
            },
            dislike(faq) {
                axios.patch(`/web/faqs/${faq.id}/dislike`)
                    .then(res => {
                        if (faq.liked) {
                            faq.liked = false;
                            faq.likes -= 1;
                        }

                        faq.disliked = !faq.disliked;
                        faq.dislikes += (faq.disliked) ? 1 : -1;
                    })
                    .catch(err => {});
            },
            onShow(e, id) {
                this.$refs.faqShow.open(id);
            },
            onEdit(e, id) {
                window.location.href = `/faqs/${id}/edit`;
            },
            onDelete(e, id) {
                Swal.fire({
                    title: 'Deletar?',
                    text: 'Deseja realmente excluir este cadastro?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Deletar',
                    cancelButtonText: 'Cancelar',
                }).then(res => {
                    if (res.value) {
                        axios.delete(`/web/faqs/${id}`)
							.then(res => {
								Flash('success', 'Atualizado com sucesso!');
                                this.load();
							})
							.catch(err => {
								Flash('error', 'Erro ao tentar deletar registro!');
							});
                    }
                });
            }
        }
    }
</script>