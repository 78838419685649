var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-music","title":"Cantigas","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Cantigas' },
            ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"songs","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
                ajax: {
                    url: '/web/songs',
                },
                columns: _vm.columns,
            },"actions":{
                create: {
                    allow: _vm.allows.create,
                    url: '/songs/create',
                },
                show: {
                    allow: _vm.allows.show,
                    callback: _vm.onShow,
                },
                edit: {
                    allow: _vm.allows.edit,
                    url: '/songs/*/edit',
                },
                delete: {
                    allow: _vm.allows.delete,
                    url: '*',
                },
                preview: {
                    allow: _vm.allows.preview,
                    callback: _vm.onPreview,
                },
                indicate: {
                    allow: _vm.allows.indicate,
                    url: '/indications/create?activity_id=@',
                },
            }}}),_vm._v(" "),_c('app-song-show',{ref:"songShow"}),_vm._v(" "),_c('app-song-preview',{ref:"songPreview"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }