<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Relatório de Professores"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Relatório de Professores' },
            ]"
        ></app-page-header>

        <app-filter
            v-if="filters.length"
            :filters="filters"
            :onChangeCallback="onFilter"
        ></app-filter>

        <app-datatable
            ref="dataTable"
            :options="{
                print: true,
                btFilter: false,
                columnsToPrint: [0,1,2],
                ajax: {
                    url: '/report/teachers'
                },
                columns: columns,
                searchCols: getSearchInit(),
            }"
            :actions="{
                chart: {
                    allow: allows.chart,
                    url: '/classrooms/report?teacher_id=*'
                },
            }"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-filter': Filter,
			'app-datatable': DataTable,
		},
        data() {
            return {
                year: '',
                filters: [
                    {
                        icon: 'fa fa-calendar',
                        name: 'year',
                        value: new Date().getFullYear(),
                        type: 'input',
                        datepicker: true,
                        index: 4,
                    }
                ]
            };
        },
        props: {
            columns: Array,
            allows: Object,
        },
        methods: {
            onFilter(data) {
                this.year = data[0].value;
                this.$refs.dataTable.onChangeSideFilter(data);
            },
            getSearchInit() {
                return [
                    null,
                    null,
                    null,
                    null,
                    { search: this.year }
                ];
            }
        }
    }
</script>