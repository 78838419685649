var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-university","title":"Relatório de Escolas","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Relatório de Escolas' },
        ]}}),_vm._v(" "),(_vm.filterReady)?_c('app-filter',{attrs:{"filters":[
            {
                icon: 'fa fa-calendar',
                name: 'year',
                value: _vm.year,
                type: 'input',
                datepicker: true,
                index: 5,
            },
            {
                icon: 'fa fa-th-list',
                name: 'state_id',
                data: _vm.states,
                index: 6,
                value: _vm.state_id,
                class: 'col-12 col-xl-4 col-md-6',
                callback: _vm.loadCities,
            },
            {
                icon: 'fa fa-th-list',
                name: 'city_id',
                data: _vm.cities,
                index: 7,
                value: _vm.city_id,
                class: 'col-12 col-xl-4 col-md-6',
            }
        ],"onChangeCallback":_vm.onFilter}}):_vm._e(),_vm._v(" "),(_vm.filterReady)?_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            print: true,
            btFilter: false,
            columnsToPrint: [0,1,2,3],
            ajax: {
                url: '/report/schools'
            },
            columns: _vm.columns,
            searchCols: [
                null,
                null,
                null,
                null,
                null,
                { search: this.year },
                { search: this.state_id },
                { search: this.city_id },
            ],
        },"actions":{
            classrooms: {
                allow: _vm.allows.classrooms,
                url: '/classrooms/report?school_id=*'
            },
        }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }