var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Professores","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Professores' }
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"teachers","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            ajax: {
                url: '/web/teachers'
            },
            columns: _vm.columns,
            searchCols: _vm.initFilter
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/teachers/create'
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/teachers/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            }
        },"filters":_vm.filters}}),_vm._v(" "),_c('app-teacher-show',{ref:"teacherShow",attrs:{"viewSchool":_vm.allows.viewSchool}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }