<template>
	<div v-if="module">
		<app-page-header
			icon="fa fa-user"
			:title="'Editar Módulo: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/modules', title: 'Módulos' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<app-input
									name="name"
                                    title="mínimo 4 caracters"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="65"
                                    :minLength="4"
                                    :required="true"
									:value="module.name"
                                ></app-input>
								<app-select
									name="year_id"
									title="Ano"
									label="Ano"
									empty="false"
									:required="true"
									:options="years"
									:value="module.year_id"
								></app-select>
								<app-input-tags
                                    ref="activities"
                                    name="activities"
                                    label="Atividades"
									placeholder="Atividades"
                                    urlData="/web/activities/list"
									title="Selecione pelo menos uma atividade"
									:fields="{ value: 'value', text: 'label' }"
                                    :required="true"
									:values="module.activities"
                                ></app-input-tags>
							</div>
							<div v-if="changeClassPlan" class="row">
								<app-file-input
									ref="classPlan"
									name="plan_url"
									label="Plano de Aula (.zip)"
									placeholder="Plano de Aula"
									accept="application/zip"
									:callback="onFileSelected"
									:required="true"
								></app-file-input>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                    <app-link
                                        type="edit"
                                        icon="pencil-square-o"
                                        title="Editar Plano de Aula"
                                        :onClick="() => { changeClassPlan = !changeClassPlan }"
                                    ></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Input from '../forms/InputComponent';
	import Select from '../forms/SelectComponent';
	import InputTags from '../forms/InputTagsComponent';
	import FileInput from '../forms/FileInputComponent';
	import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
            'app-input': Input,
			'app-select': Select,
			'app-input-tags': InputTags,
			'app-file-input': FileInput,
            'app-button': Button,
            'app-link': Link,
		},
		data() {
			return {
				module: null,
				changeClassPlan: false,
				years: [],
				classPlan: null,
			}
		},
		props: {
			id: String,
            backUrl: String,
		},
		mounted() {
			this.loadYears();
			this.load(this.id);
		},
		methods: {
			loadYears() {
				axios.get('/web/years/list')
					.then(res => {
						this.years = res.data;
					})
					.catch(err => {});
			},
			load(id)  {
				axios.get(`/web/modules/${id}`)
                    .then(res => {
                        this.module = res.data;
                    })
                    .catch(err => {});
			},
			onFileSelected(file) {
				this.classPlan = file;
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

					if (this.changeClassPlan) {
						formData.append('class_plan', this.classPlan);
					}

					for (let tag of this.$refs.activities.selectedData) {
						formData.append('activities[]', tag.value);
					}

                    axios.post(`/web/modules/${this.module.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/modules';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
			}
		}
	}
</script>