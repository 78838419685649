<template>
    <div>
        <app-page-header
            icon="fa fa-thumb-tack"
			:title="`Indicar : ${title}`"
 			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '', title: 'Indicar' },
			]"
        ></app-page-header>

        <div class="row">
            <div class="col-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <app-select
                                    label="Sala"
                                    title="Sala"
                                    type="select"
                                    :required="true"
                                    :options="classrooms"
                                    :callback="loadStudents"
                                ></app-select>
                                <div class="col-12">
                                    <div class="card card-light">
                                        <div class="card-header">
                                            <span class="pull-left">Alunos</span>
                                            <div 
                                                class="animated-checkbox checkbox-xs-2 pull-right"
                                            >
                                                <app-checkbox
                                                    name="all"
                                                    label="Todos"
                                                    :callback="selectAll"
                                                ></app-checkbox>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div id="studentsBox" class="row" ref="studentsBox">
                                                <app-checkbox
                                                    v-for="student in students" 
                                                    :key="student.data.id"
                                                    name="dataStudentIds[]"
                                                    :label="student.name"
                                                    placeholder="Aluno"
                                                    :value="student.data.id"
                                                    :validated="!student.hasIndication"
                                                ></app-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div 
                    v-if="hasIndicationPending" 
                    class="alert alert-warning alert-dismissible" 
                    role="alert"
                >
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <strong>Atenção!</strong>
                    Existem indicações pendentes para os alunos destacados em vermelho!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent.vue';
    import Select from '../forms/SelectComponent.vue';
    import CheckboxGroup from '../forms/CheckboxGroupComponent.vue';
    import Checkbox from '../forms/CheckboxComponent.vue';
    import Button from '../forms/ButtonComponent.vue';
    import Link from '../forms/LinkComponent.vue';
    
    export default {
        components: {
            'app-page-header': PageHeader,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
            'app-checkbox': Checkbox,
            'app-checkbox-group': CheckboxGroup,
        },
        data() {
            return {
                classrooms: [],
                students: [],
                studentsSelected: [],
                selected: false,
                hasIndicationPending: false,
            }
        },
        props: {
            title: String,
            backUrl: String,
            activityId: Number
        },
        mounted() {
            this.loadClassrooms();
        },
        methods: {
            loadClassrooms() {
                axios
                    .get(`/web/classrooms/list?toTags=true`)
                    .then((res) => {
                        this.classrooms = res.data;
                    });
            },
            loadStudents(classroomId) {
                this.selectedStudents = false;
                
                if (classroomId) {
                    axios
                        .get(`/web/students/findByClassroom/${classroomId}/with_indication`)
                        .then(res => {
                            this.students = res.data;
                            
                            this.hasIndicationPending = this.students.some(s => s.hasIndication);
                        });
                } else {
                    $(this.$refs.studentsBox).html('');
                }
            },
            selectAll(e) {
                this.selected = !this.selected;
                let checkboxs = $(this.$refs.studentsBox).find('input');
                
                $.each(checkboxs, (i, val) => {
                    $(val).prop('checked', this.selected);
                });
            },
            selectedAnyPending(dataStudentIds) {
                for (let student of this.students) {
                    let dataStudentId = String(student.data.id);

                    if (student.hasIndication && dataStudentIds.includes(dataStudentId)) {
                        return true;
                    }
                }

                return false;
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    let dataStudentIds = formData.getAll('dataStudentIds[]');
                    let hasStudentsSelected = this.selectedAnyPending(dataStudentIds);
                    
                    new Promise(async (result) => {
                        if (hasStudentsSelected) {
                            Swal.fire({
                                title: 'Aviso',
                                type: 'warning',
                                text: `
                                    Existem Alunos com indicação pendente,
                                    ignorar e sobrescrever?
                                `,
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não',
                                showCancelButton: true,
                            }).then(res => {
                                formData.append('override', res.value ? '1' : '0');

                                result(true);
                            });
                        } else {
                            formData.append('override', '0');
                            result(true);
                        }
                    }).then(() => {
                        formData.append('activity_id', this.activityId);

                        axios.post('/web/indications', formData)
                            .then(res => {
                                Swal.fire({
                                    title: 'Salvo com sucesso!',
                                    type: 'success'
                                })
                                .then(() => {
                                    window.location.href = this.backUrl;
                                });
                            })
                            .catch(err => {
                                Flash('error', 'Erro ao tentar indicar!');
                            });
                    });
                }
            }
        }
    }
</script>