var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-gamepad","title":"Jogos","breadcrumbs":[
				{ href: '/', title: 'Painel' },
				{ href: '', title: 'Jogos' },
			]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"games","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
				ajax: {
					url: '/web/games',
				},
				columns: _vm.columns,
			},"actions":{
				create: {
					allow: _vm.allows.create,
					url: '/games/create',
				},
				edit: {
					allow: _vm.allows.edit,
					url: '/games/*/edit',
				},
				show: {
					allow: _vm.allows.show,
					callback: _vm.onShow,
				},
				delete: {
					allow: _vm.allows.delete,
					url: '*',
				},
				preview: {
					allow: _vm.allows.preview,
					callback: _vm.onPreview,
				},
				indicate: {
                    allow: _vm.allows.indicate,
                    url: '/indications/create?activity_id=@',
                },
			}}}),_vm._v(" "),_c('app-game-show',{ref:"gameShow"}),_vm._v(" "),_c('app-game-preview',{ref:"gamePreview"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }