<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Diretores"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Diretores' }
            ]"
        ></app-page-header>

        <app-datatable
            entity="directors"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/directors'
                },
                columns: columns
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/directors/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: allows.edit,
                    url: '/directors/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                }
            }"
            :filters="[
                {
                    name: 'school_id',
                    type: 'select',
                    label: 'Escola',
                    liveSearch: true,
                    index: 4,
                    options: schools
                }
            ]"
        ></app-datatable>

        <app-director-show ref="directorShow"></app-director-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
  	import DataTable from '../DataTableComponent';
    import DirectorShow from './DirectorShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-director-show': DirectorShow
        },
        data() {
            return {
                schools: [],
            }
        },
        props: {
            allows: Object,
            columns: Array,
        },
        mounted() {
            this.loadSchools();
        },
        methods: {
            onShow(id) {
                this.$refs.directorShow.open(id);
            },
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        this.schools = res.data;
                    })
                    .catch(err => {});
            },
        }
    }
</script>
