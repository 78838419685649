<template>
	<div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg with-thumb" role="document">
			<div class="modal-content">
				<div class="modal-header border-0">
					<img
						v-if="!loading"
						:src="student.avatar_url.replace('*', '120')"
						class="thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow"
					>
					<h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
					<div class="row content">
						<div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>ID</strong></p>
									<p>{{ student.id }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Nome</strong></p>
									<p>{{ student.name }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>RA</strong></p>
									<p>{{ student.ra ?? '-' }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Usuário</strong></p>
									<p>{{ student.user.login }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Senha</strong></p>
									<p class="d-flex">
										<img
											class="fruit-sm"
											v-for="fruit in student.pass.split(' ')" 
                                        	:src="'/assets/img/fruits/bt_' + fruit + '.png'"
										>
									</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Status</strong></p>
									<p>{{ student.active_text }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Cadastrado em</strong></p>
									<p>{{ new Date(student.created_at).toLocaleDateString() }}</p>
								</div>
								<div v-if="!student.responsible" class="col-12 mb-3">
									<p>
                                        <strong>Escola</strong>
                                    </p>
                                    <ul class="list-group">
										<p v-if="!student.data" class="alert alert-danger">
                                            Aluno sem escola
                                        </p>
										<a
											v-if="student.data"
											:class="[
												{'disabled': !this.viewSchool},
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]"
											:href="'/schools?id=' + student.data.school.id"
										>
											{{ student.data.school.name }}
											<span
												class="badge badge-pill px-3"
												:class="[
													student.data.school.active
														? 'badge-success'
														: 'badge-secondary'
											]">
												{{ student.data.school.active_text }}
											</span>
										</a>
                                    </ul>
								</div>
								<div v-if="!student.responsible" class="col-12">
                                    <p>
                                        <strong>Sala</strong>
                                    </p>
                                    <ul class="list-group">
										<p
											v-if="!student.data"
											class="alert alert-danger"
										>
                                            Aluno sem sala
                                        </p>
										<a
											v-if="student.data"
											:class="[
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]"
											:href="'/classrooms?id=' + student.data.classroom.id"
										>
											{{ student.data.classroom.name }}
											<span
												class="badge badge-pill px-3"
												:class="[
													student.data.classroom.active
														? 'badge-success'
														: 'badge-light'
											]">
												{{ student.data.classroom.active_text }}
											</span>
										</a>
                                    </ul>
                                </div>
								<div v-if="student.responsible" class="col-12">
									<p>
                                        <strong>Responsável</strong>
                                    </p>
									<ul class="list-group">
										<p
											:class="[
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]"
										>
											{{ student.responsible.name }}
										</p>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				student: {}
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Aluno' },
			viewSchool: Boolean,
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/students/${id}`)
					.then(res => {
						this.loading = false;
						this.student = res.data;
						this.title = `${this.defaultTitle}`;
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
	}
</script>