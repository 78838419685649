<template>
    <div>
        <app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
                { href: '', title: 'Painel' },
            ]"
        ></app-page-header>

        <div class="row">
            <app-jumbotron :name="'Dir. ' + userName"></app-jumbotron>
        </div>

        <app-filter
            v-if="filters.length && filters[0].data.length"
            :filters="filters"
            :onChangeCallback="onFilter"
        ></app-filter>

        <div class="row">
            <app-widget
                type="danger"
                icon="fa fa-book"
                title="Professores"
                :value="teachersCount"
                :link="{
                    label: 'Desempenho',
                    url: '/teachers/report'
                }"
            ></app-widget>
            <app-widget
                type="success"
                icon="fa fa-graduation-cap"
                title="Salas"
                :value="classroomsCount"
                :link="{
                    label: 'Desempenho',
                    url: '/classrooms/report'
                }"
            ></app-widget>
            <app-widget
                type="info"
                icon="fa fa-user"
                title="Alunos"
                :value="studentsCount"
                :link="{
                    label: 'Desempenho',
                    url: '/students/report'
                }"
            ></app-widget>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Jumbotron from '../JumbotronComponent';
    import Filter from '../FilterComponent';
    import Widget from './WidgetComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-jumbotron': Jumbotron,
            'app-filter': Filter,
            'app-widget': Widget,
		},
        data() {
            return {
                filters: [],
                teachersCount: 0,
                classroomsCount: 0,
                studentsCount: 0,
            }
        },
        props: {
            userName: String,
        },
        mounted() {
            this.loadSchools();
        },
        methods: {
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        this.filters.push({
                            icon: 'fa fa-university',
                            name: 'school_id',
                            value: res.data[0].value,
                            data: res.data,
                        });
                    })
                    .catch(err => {});
            },
            onFilter(data) {
                this.load(data[0]);
            },
            load(data) {
                axios.get(`/web/dashboards?${data.name}=${data.value}`)
                    .then(res => {
                        this.teachersCount = res.data.teachersCount;
                        this.classroomsCount = res.data.classroomsCount;
                        this.studentsCount = res.data.studentsCount;
                    })
                    .catch(err => {});
            }
        }
    }
</script>