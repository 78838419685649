<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-question"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
							<div class="row">
                                <div class="col-12">
									<p><strong>Pergunta</strong></p>
									<p>{{ data.question }}</p>
								</div>
                                <div class="col-12">
									<p><strong>Resposta</strong></p>
									<p v-html="data.answer"></p>
								</div>
                                <div class="col-12">
                                    <fieldset>
                                        <legend class="h6">Disponível em quais perfis?</legend>
                                        <div class="list-group">
                                            <div
                                                v-for="profile of data.profiles"
                                                class="list-group-item"
                                            >
                                                {{ profile.title }}
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				data: {}
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Pergunta' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
        methods: {
            open(id) {
                this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/web/faqs/${id}`)
					.then(res => {
						this.loading = false;
						this.data = res.data;
						this.title = `${this.defaultTitle}: ${this.data.id}`;
					})
					.catch(err => {});
            }
        }
    }
</script>