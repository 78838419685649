<template>
	<div :class="containerClass">
		<div :class="[subClass, { 'd-inline-flex align-items-center' : inline }]">
			<label v-if="label && position == 'start'" :for="name">{{ label }}</label>
			<div ref="toggle" class="toggle lg">
				<input
					v-if="type == 'radio'"
					type="hidden"
					:name="name"
					:data-type="type"
					value="false"
					v-model="selected"
					@click="onChange"
				>
				<label>
					<input
						type="checkbox"
						:name="type == 'radio' ? '' : name"
						:data-type="type"
						:value="type == 'radio' ? 'true' : value"
						v-model="selected"
						@change="onChange"
					>
					<span class="button-indecator"></span>
				</label>
			</div>
			<label v-if="label && position == 'end'" :for="name">{{ label }}</label>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				selected: '',
			};
		},
		props: {
			type: { type: String, default: 'radio' },
			label: String,
			name: String,
			value: Boolean | String,
			containerClass: { type: String, default: 'col-12 col-xl-3 col-md-6' },
			subClass: { type: String, default: 'col-12' },
			label: String,
			callback: Function,
			position: { type: String, default: 'start' },
			inline: { type: Boolean, default: false },
			checked: Boolean,
		},
		watch: {
			value(newValue, oldValue) {
				this.selected = newValue;
			}
		},
		mounted() {
			if (!['radio','checkbox'].includes(this.type)) {
				throw new Error('type could be radio or checkbox!');
			}

			if (this.type == 'checkbox') {
				this.selected = (this.checked) ? this.value : '';
			} else {
				this.selected = this.value;
			}
		},
		methods: {
			onChange(e) {
				if (this.callback) {
					this.callback(this.selected, e);
				}
			}
		}
	}
</script>