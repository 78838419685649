<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
                    <h5 class="modal-title">
						<i class="fa fa-graduation-cap"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
                </div>
                <div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="row">
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Nome</strong></p>
                                    <p>{{ data.name }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Ano</strong></p>
                                    <p>{{ data.year.title }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Escola</strong></p>
                                    <p>{{ data.school.name }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Status</strong></p>
                                    <p>{{ data.active_text }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <p><strong>Professores</strong></p>
                                    <ul class="list-group">
                                        <p v-if="!data.teachers.length" class="alert alert-danger">
                                            Sala sem professor
                                        </p>
                                        <a
											:class="[
                                                {disabled: isTeacher},
                                                'list-group-item btn btn-outline-info d-flex',
                                                'justify-content-between'
                                            ]"
                                            v-for="teacher of data.teachers"
											:href="'/teachers?id=' + teacher.id"
										>
											{{ teacher.name }}
											<span
												class="badge badge-pill px-3"
												:class="[
													teacher.active
														? 'badge-success'
														: 'badge-secondary'
											]">
												{{ teacher.active_text }}
											</span>
										</a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modal: HTMLElement,
                title: '',
                loading: true,
                data: {}
            }
        },
        props: {
            defaultTitle: { type: String, default: 'Sala' },
            isTeacher: Boolean,
        },
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {
                this.loading = true;
                this.modal = $(this.$refs.modal);
                this.title = 'Carregando...';
                this.modal.modal('show');

                axios.get(`/web/classrooms/${id}`)
                    .then(res => {
                        this.loading = false;
                        this.data = res.data;
                        this.title = `${this.defaultTitle}: ${this.data.id}`;
                    })
                    .catch(err => {});
            },
            onClose() {
                this.modal.modal('hide');
            },
            goBack() {
                window.history.go(-1);
            }
        }
    }
</script>