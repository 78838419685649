<template>
    <div
		:id="containerId"
		:class="containerClass"
	>
        <label v-if="label">
            {{ label }}
            <a
                v-if="help"
                :id="id"
                tabindex="0"
                class="btn btn-outline-info btn-circle btn-circle-xsm btn-help"
                data-toggle="popover"
                data-trigger="focus"
                role="button"
                :data-content="help.text"
            >
                <i class="fa fa-question"></i>
            </a>
        </label>
        <div class="input-daterange input-group" :id="id">
            <div class="input-group-prepend">
                <span class="input-group-text">{{ textStart }}</span>
            </div>
            <input
                ref="fieldStart"
				:name="nameStart"
				:class="[{ 'is-invalid' : error }, className]"
				:minlength="minLength"
				:maxlength="maxLength"
				:title="title"
				:disabled="disabled"
				:required="required"
				:readonly="readonly"
				:placeholder="placeholderStart"
				v-model="inputStart"
				@change="onChange"
			>
            <div class="input-group-append input-group-prepend">
                <span class="input-group-text">{{ textEnd }}</span>
            </div>
            <input
                ref="fieldEnd"
				:name="nameEnd"
				:class="[{ 'is-invalid' : error }, className]"
				:minlength="minLength"
				:maxlength="maxLength"
				:title="title"
				:disabled="disabled"
				:required="required"
				:readonly="readonly"
				:placeholder="placeholderEnd"
				v-model="inputEnd"
				@change="onChange"
			>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                inputStart: '',
                inputEnd: '',
            }
        },
        props: {
            id: String,
            label: String,
            title: String,
            minLength: Number,
			maxLength: Number,
            required: Boolean,
			readonly: Boolean,
			disabled: { type: Boolean, default: false },
            containerId: String,
            className: { type: String, default: 'form-control' },
			containerClass: { type: String, default: 'col-12 col-xl-4 col-md-6' },
			help: Object,
			error: String,
            format: { type: String, default: 'dd/mm/YY' },
            startDate: String,
            endDate: String,
            callback: Function,

            nameStart: String,
            valueStart: String,
            textStart: String,
            placeholderStart: String,
            
            nameEnd: String,
            valueEnd: String,
            textEnd: String,
            placeholderEnd: String,
        },
        mounted() {
            this.inputStart = this.valueStart ?? '';
            this.inputEnd = this.valueEnd ?? '';

            $(`#${this.id}`).datepicker({
					format: this.format,
					startDate: this.startDate,
					endDate: this.endDate,
					language: 'pt-BR',
					todayHighlight: true,
					autoclose: true
				});

            $(this.$refs.fieldStart).datepicker('setDate', this.inputStart);
            $(this.$refs.fieldEnd).datepicker('setDate', this.inputEnd);
        },
        watch: {
            valueStart(newValue, oldValue) {
				this.inputStart = newValue;
			},
            valueEnd(newValue, oldValue) {
				this.inputEnd = newValue;
			}
        },
        methods: {
            onChange(e) {
                if (this.callback) {
                    this.callback(this.inputStart, this.inputEnd, e);
                }
            }
        }
    }
</script>