<template>
	<div>
		<div class="box" v-for="(student, index) in students">
			<div class="body">
				<div class="name">
					<strong>Nome: </strong> {{ student.name }}
				</div>
				<div class="login">
					<div class="user">
						<strong>Usuário: </strong> {{ student.login }}
					</div>
					<div class="password">
						<strong>Senha: </strong>
						<div class="images" v-for="code in student.pass.split(' ')">
							<img :src="'/assets/img/fruits/bt_' + code + '.png'">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				students: [],
			}
		},
		props: {
			classroomId: String,
			studentId: String,
		},
		mounted() {
			this.load();
		},
		updated() {
			this.print();
		},
		methods: {
			load() {
				let param = (this.classroomId)
					? `classroom_id=${this.classroomId}`
					: `student_id=${this.studentId}`;

				axios.get(`/web/students/print?${param}`)
					.then(res => {
						this.students = res.data;
					})
					.catch(err => {});
			},
			print() {
				var page = window.open();

				if (page) {
					page.document.write($('html').html());
					page.document.close();
					page.onload = function () {
						page.print();
						page.close();
						history.back();
					}
				}
			}
		}
	}
</script>
