var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-dashboard","title":"Painel","breadcrumbs":[
            { href: '', title: 'Painel' },
        ]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-jumbotron',{attrs:{"name":_vm.userName}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[(_vm.modules)?_c('app-widget',{attrs:{"type":"warning","icon":"fa fa-th-list","title":"Módulos","value":_vm.modules.total,"link":{
                label: 'Ranking de uso',
                url: '/modules/report'
            },"data":[
                { label: 'Ativos', value: _vm.modules.enabled },
                { label: 'Inativos', value: _vm.modules.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.activities)?_c('app-widget',{attrs:{"type":"warning","icon":"fa fa-archive","title":"Atividades","value":_vm.activities.total,"link":{
                label: 'Ranking de uso',
                url: '/activities/report'
            },"data":[
                { label: 'Ativos', value: _vm.activities.enabled },
                { label: 'Inativos', value: _vm.activities.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.schools)?_c('app-widget',{attrs:{"type":"success","icon":"fa fa-university","title":"Escolas","value":_vm.schools.total,"link":{
                label: 'Desempenho',
                url: '/schools/report'
            },"data":[
                { label: 'Ativos', value: _vm.schools.enabled },
                { label: 'Inativos', value: _vm.schools.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.classrooms)?_c('app-widget',{attrs:{"type":"success","icon":"fa fa-graduation-cap","title":"Salas","value":_vm.classrooms.total,"link":{
                label: 'Desempenho',
                url: '/classrooms/report'
            },"data":[
                { label: 'Ativos', value: _vm.classrooms.enabled },
                { label: 'Inativos', value: _vm.classrooms.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.directors)?_c('app-widget',{attrs:{"type":"info","icon":"fa fa-user","title":"Diretores","value":_vm.directors.total,"link":{
                label: 'Acessos (' + _vm.logs.directors + ')',
            },"data":[
                { label: 'Ativos', value: _vm.directors.enabled },
                { label: 'Inativos', value: _vm.directors.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.teachers)?_c('app-widget',{attrs:{"type":"info","icon":"fa fa-user","title":"Professores","value":_vm.teachers.total,"link":{
                label: 'Acessos (' + _vm.logs.teachers + ')',
            },"data":[
                { label: 'Ativos', value: _vm.teachers.enabled },
                { label: 'Inativos', value: _vm.teachers.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.responsibles)?_c('app-widget',{attrs:{"type":"info","icon":"fa fa-user","title":"Responsáveis","value":_vm.responsibles.total,"link":{
                label: 'Acessos (' + _vm.logs.responsibles + ')',
            },"data":[
                { label: 'Ativos', value: _vm.responsibles.enabled },
                { label: 'Inativos', value: _vm.responsibles.disabled },
            ]}}):_vm._e(),_vm._v(" "),(_vm.students)?_c('app-widget',{attrs:{"type":"info","icon":"fa fa-user","title":"Alunos","value":_vm.students.total,"link":{
                label: 'Acessos (' + _vm.logs.students + ')',
                url: 'students/report'
            },"data":[
                { label: 'Ativos', value: _vm.students.enabled },
                { label: 'Inativos', value: _vm.students.disabled },
            ]}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }