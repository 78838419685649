<template>
    <div>
        <app-page-header
            icon="fa fa-thumb-tack"
            title="Relatório de Indicações"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Relatório de Indicações' },
            ]"
        ></app-page-header>

        <app-filter
            :filters="[
                {
                    icon: 'fa fa-calendar',
                    name: 'year',
                    value: year,
                    type: 'input',
                    datepicker: true,
                    index: 4,
                }
            ]"
            :onChangeCallback="onFilter"
        ></app-filter>

        <app-datatable
            ref="dataTable"
            :options="{
                order: [0, 'desc'],
                print: true,
                columnsToPrint: [0,1,2,3],
                ajax: {
                    url: '/report/indications'
                },
                columns: columns,
                searchCols: [
                    null,
                    null,
                    null,
                    null,
                    { search: year },
                ],
                ranges: ['range']
            }"
            :actions="{
                student: {
                    allow: allows.students,
                    url: '/indications/*/report'
                },
            }"
            :filters="[
                {
                    type: 'range',
                    name: 'range',
                }
            ]"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-filter': Filter,
			'app-datatable': DataTable,
        },
        data() {
            return {
                year: new Date().getFullYear(),
            };
        },
        props: {
            columns: Array,
            allows: Object,
        },
        methods: {
            onFilter(data) {
                this.year = data.filter(o => o.name == 'year')[0].value;
                
                this.$refs.dataTable.onChangeSideFilter(data);
            }
        }
    }
</script>