<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Relatório de Alunos"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Relatório de Alunos' },
            ]"
        ></app-page-header>

        <div v-if="isManager" class="row">
            <div class="col-12">
                <div class="tile">
                    <app-input-toggle
                        name="reportType"
                        on-style="secondary"
                        off-style="secondary"
                        on-label="Escola"
                        off-label="Responsável"
                        :checked="true"
                        :callback="onChangeReportType"
                    ></app-input-toggle>
                </div>
            </div>
        </div>

        <app-filter
            v-if="filterReady"
            :filters="[
                {
                    icon: 'fa fa-calendar',
                    name: 'year',
                    value: year,
                    type: 'input',
                    datepicker: true,
                    index: 5,
                },
                {
                    icon: 'fa fa-university',
                    name: 'school_id',
                    data: schools,
                    index: 7,
                    value: school_id,
                    class: 'col-12 col-xl-4 col-md-6',
                    callback: loadClassrooms,
                    disabled: isResponsible || responsibleFilterEnabled,
                },
                {
                    icon: 'fa fa-graduation-cap',
                    name: 'classroom_id',
                    data: classrooms,
                    index: 6,
                    value: classroom_id,
                    class: 'col-12 col-xl-4 col-md-6',
                    disabled: isResponsible || responsibleFilterEnabled,
                },
                {
                    icon: 'fa fa-user',
                    name: 'responsible_id',
                    data: responsibles,
                    index: 8,
                    value: responsible_id,
                    class: 'col-12 col-xl-4 col-md-6',
                    disabled: !responsibleFilterEnabled,
                }
            ]"
            :onChangeCallback="onFilter"
        ></app-filter>

        <app-datatable
            v-if="filterReady"
            ref="dataTable"
            :options="{
                print: true,
                btFilter: false,
                columnsToPrint: [0,1,3],
                ajax: {
                    url: '/report/students'
                },
                columns: columns,
                searchCols: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    { search: this.year },
                    { search: this.classroom_id },
                    { search: this.school_id },
                ],
            }"
            :actions="{
                chart: {
                    allow: allows.student,
                    url: '/students/*/report'
                },
            }"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
	import DataTable from '../DataTableComponent';
    import InputToggle from '../forms/InputToggleComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-filter': Filter,
			'app-datatable': DataTable,
            'app-input-toggle': InputToggle,
		},
        data() {
            return {
                year: null,
                filterReady: false,
                isResponsible: false,
                isManager: false,
                schools: [],
                classrooms: [],
                school_id: null,
                classroom_id: null,
                responsibles: [],
                responsible_id: null,
                responsibleFilterEnabled: false,
            };
        },
        props: {
            profileCode: String,
            columns: Array,
            allows: Object,
            classroomId: Number,
        },
        mounted() {
            this.isResponsible = (this.profileCode == 'responsible');
            this.isManager = (this.profileCode == 'manager');
            this.year = new Date().getFullYear();

            if (this.isResponsible) {
                this.filterReady = true;
            } else if (this.isManager) {
                this.responsibleFilterEnabled = false;
                this.loadResponsibles();

                if (this.classroomId) {
                    this.loadClassroom();
                } else {
                    this.loadSchools();
                }
            } else {
                if (this.classroomId) {
                    this.loadClassroom();
                } else {
                    this.loadSchools();
                }
            }
        },
        methods: {
            loadClassroom()
            {
                axios.get(`/web/classrooms/${this.classroomId}`)
                    .then(res => {
                        this.year = new Date(res.data.created_at).getFullYear();
                        this.school_id = res.data.school_id;

                        this.loadSchools();
                    })
                    .catch(err => {});
            },
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        this.schools = res.data;
                        
                        if (!this.school_id) {
                            this.school_id = res.data[0].value;
                        }
                        
                        this.loadClassrooms();
                    })
                    .catch(err => {});
            },
            loadClassrooms(data) {
                if (data) { this.school_id = data.value; }

                this.classroom_id = null;

                axios.get(`/web/classrooms/findBySchool/${this.school_id}?toTags=true`)
                    .then(res => {
                        this.classrooms = res.data;

                        if (this.classroomId) {
                            this.classroom_id = this.classroomId;
                        } else {
                            this.classroom_id = res.data[0].value;
                        }
                        
                        if (!this.filterReady) {
                            this.filterReady = true;
                        } else {
                            this.onFilter([
                                { name: 'year', value: this.year },
                                { name: 'classroom_id', value: this.classroom_id },
                                { name: 'school_id', value: this.school_id },
                            ]);
                        }
                    })
                    .catch(err => {});
            },
            loadResponsibles() {
                axios.get(`/web/responsibles/list`)
                    .then(res => {
                        this.responsibles = [{ label: 'Responsáveis', value: '' }];
                        this.responsibles.push(...res.data);
                        this.responsible_id = '';
                        this.filterReady = true;
                    })
                    .catch(err => {});
            },
            onFilter(data) {
                this.year = data.filter(o => o.name == 'year')[0].value;

                if (this.responsibleFilterEnabled) {
                    this.school_id = null;
                    this.classroom_id = null;
                    this.responsible_id = data.filter(o => o.name == 'responsible_id')[0].value;
                } else {
                    this.responsible_id = null;
                    this.classroom_id = data.filter(o => o.name == 'classroom_id')[0].value;
                }
                
                if ((this.classroom_id || this.responsible_id) || this.isResponsible) {
                    this.$refs.dataTable.onChangeSideFilter(data);
                }
            },
            onChangeReportType(bool) {
                let data = {};

                if (!bool) {
                    data = [
                        { name: 'responsible_id', value: this.responsible_id },
                        { name: 'year', value: this.year },
                        { name: 'classroom_id', value: null },
                        { name: 'school_id', value: null },
                    ];
                } else {
                    data = [
                        { name: 'year', value: this.year },
                        { name: 'classroom_id', value: this.classroom_id },
                        { name: 'school_id', value: this.school_id },
                    ];
                }
                
                this.responsibleFilterEnabled = !bool;
                
                this.onFilter(data);
            }
        }
    }
</script>