var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Pais ou Responsáveis","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Pais ou Responsáveis' }
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"responsibles","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":4,"options":{
            ajax: {
                url: '/web/responsibles'
            },
            columns: _vm.columns
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/responsibles/create'
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/responsibles/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            }
        },"filters":[
            {
                name: 'cpf',
                type: 'input',
                label: 'CPF',
                index: 5,
            }
        ]}}),_vm._v(" "),_c('app-responsible-show',{ref:"responsibleShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }