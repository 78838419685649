<template>
    <div v-if="game">
        <app-page-header
            :title="'Editar Jogo: ' + id"
            icon="fa fa-gamepad"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/games', title: 'Jogos' },
                { href: '', title: 'Editar' }
            ]"
        ></app-page-header>

        <div class="row">
            <div class="col-12">
                <div class="tile">
                    <div class="tile-body">
                        <form ref="form">
                            <div class="row">
                                <div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb" 
                                        class="thumb-activities img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Inserir Imagem"
                                        :outline="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                    <input
                                        type="hidden"
                                        name="removeThumb"
                                        :value="removeThumb"
                                    >
                                </div>
                                <app-input
                                    title="mínimo 2 caracters"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="65"
                                    :minLength="2"
                                    :required="true"
                                    :value="game.title"
                                ></app-input>
                                <app-input
                                    title="mínimo 2 caracters"
                                    label="Assunto"
                                    name="subject"
                                    placeholder="Assunto"
                                    :maxLength="65"
                                    :minLength="2"
                                    :required="true"
                                    :value="game.subject"
                                ></app-input>
                                <app-select
                                    name="screen_mode"
                                    label="Formato de Tela"
                                    title="Formato de Tela"
                                    :required="true"
                                    :options="screenTypes"
                                    :value="game.screen_mode"
                                ></app-select>
                                <app-textarea
                                    name="description"
                                    label="Descrição"
                                    placeholder="Descrição"
                                    :maxLength="300"
                                    resize="none"
                                    :value="game.description"
                                ></app-textarea>
                                <app-toggle
									label="Status"
									name="active"
									:value="game.active"
								></app-toggle>
                            </div>
                            <div class="row">
                                <app-input-tags
                                    ref="tags"
                                    name="tags"
                                    label="Tags"
                                    urlData="/web/tags/list"
                                    :fields="{ value: 'value', text: 'label' }"
                                    :values="game.tags"
                                ></app-input-tags>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>

                        <app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Textarea from '../forms/TextareaComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import ImageSelect from '../ImageSelectComponent';
    import InputTags from '../forms/InputTagsComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-textarea': Textarea,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
            'app-input-tags': InputTags,
            'app-toggle': Toggle,
        },
        data() {
            return {
                game: null,
                DEFAULT_IMAGE: '/assets/img/default_thumbs/game.png',
                tempImage: '',
                screenTypes: [],
                removeThumb: 0
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.tempImage = this.DEFAULT_IMAGE;
            this.load(this.id);
            this.loadScreenTypes();
        },
        methods: {
            load(id) {
                axios.get(`/web/games/${id}`)
                    .then(res => {
                        this.game = res.data;
                        this.tempImage = this.game.thumb;
                    })
                    .catch(err => {});
            },
            loadScreenTypes() {
                axios.get('/web/games/screenTypes')
                    .then(res => {
                        this.screenTypes = res.data;
                    })
                    .catch(err => {});
            },
            openEditImage() {
                this.$refs.imageSelect.open();
            },
            imageLoaded(image) {
                this.removeThumb = (image != null) ? 0 : 1;

                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;                    
                }
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);

				formData.append('_method', 'PATCH');
                formData.append('thumb', this.$refs.imageSelect.file);

                for (let tag of this.$refs.tags.selectedData) {
                    formData.append('tags[]', tag.value);
                }

				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					Flash('error', validate);
				} else {
					axios.post(`/web/games/${this.game.id}`, formData)
						.then(res => {
							Swal.fire({
								title: 'Atualizado com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/games';
							});
						})
						.catch(err => {
							Flash('error', 'Erro ao tentar atualizar registro!');
						});
				}
            }
        }
    }
</script>