<template>
	<div>
		<app-page-header
			icon="fa fa-user"
			title="Cadastrar Aluno"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/students', title: 'Alunos' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
								<app-select
									v-if="type == 'school'"
									name="school_id"
									label="Escola"
									title="Escola"
									empty="false"
									:required="true"
									:options="schools"
									:data-live-search="true"
									:callback="loadClassrooms"
								></app-select>
								<app-select
									v-if="type == 'school'"
									ref="classrooms"
									name="classroom_id"
									label="Sala"
									title="Sala"
									empty="false"
									:required="true"
									:options="classrooms"
									:data-live-search="true"
								></app-select>
								<app-select
									v-if="type == 'responsible'"
									name="year_id"
									label="Ano"
									title="Ano"
									empty="false"
									:required="true"
									:options="years"
									:data-live-search="true"
								></app-select>
								<app-select
									v-if="type == 'responsible'"
									name="responsible_id"
									label="Responsável"
									title="Responsável"
									empty="false"
									:required="true"
									:options="responsibles"
									:data-live-search="true"
								></app-select>
								<app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome Completo"
                                    placeholder="Nome Completo"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="true"
									:callback="generateCredentials"
                                ></app-input>
								<app-input
                                    name="ra"
                                    label="RA"
                                    placeholder="RA"
                                    :maxLength="15"
                                ></app-input>
								<app-input
									title="apenas letras e números, mínimo 4 caracteres"
                                    name="user[login]"
                                    label="Usuário"
                                    placeholder="Usuário"
                                    :maxLength="50"
                                    :minLength="4"
                                    :required="true"
									pattern="[A-Za-z0-9]{4,12}"
                                    :value="tempLogin"
                                ></app-input>
								<app-input
                                    name="user[password]"
                                    label="Senha"
                                    placeholder="Senha"
                                    :required="true"
                                    :readonly="true"
									:value="tempPass"
                                ></app-input>
							</div>
							<hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-button': Button,
            'app-link': Link,
		},
		data() {
			return {
				type: '',
				years: [],
				schools: [],
				classrooms: [],
				responsibles: [],
				tempLogin: '',
				tempPass: '',
			}
		},
		props: {
			profileCode: String,
			backUrl: String,
		},
		mounted() {
			if (['manager','responsible'].includes(this.profileCode)) {
				this.loadYears();
				this.loadResponsibles();
			}
			
			if (!['responsible'].includes(this.profileCode)) {
				this.loadSchools();
			}

			if (!['director', 'teacher', 'responsible'].includes(this.profileCode)) {
				Swal.fire({
					type: 'warning',
					title: 'Qual será o vínculo?',
					confirmButtonText: 'Escola',
					cancelButtonText: 'Responsável',
					showCancelButton: true,
					allowOutsideClick: false,
				}).then(async res => {
					this.type = (res.value) ? 'school' : 'responsible';

					await Help.delay(100);

					$('.selectpicker').selectpicker('render');
				});
			} else {
				this.type = (!['responsible'].includes(this.profileCode))
					? 'school'
					: 'responsible';
			}
		},
		methods: {
			loadSchools() {
				axios.get('/web/schools/list?toTags=true')
					.then(res => {
						this.schools = res.data;
					})
					.catch(err => {});
			},
			loadClassrooms(school_id) {
				axios.get(`/web/classrooms/findBySchool/${school_id}?toTags=true`)
					.then(async res => {
						this.classrooms = res.data;
						
						await Help.delay(100);

						this.$refs.classrooms.refresh();
					})
					.catch (err => {});
			},
			loadYears()
			{
				axios.get('/web/years/list')
					.then(res => {
						this.years = res.data;
					})
					.catch(err => {});
			},
			loadResponsibles() {
				axios.get('/web/responsibles/list')
					.then(res => {
						this.responsibles = res.data;
					})
					.catch(err => {});
			},
			generateCredentials(name) {
				this.generateLogin(name);
				this.generatePass();
			},
			generateLogin(name) {
				axios.get(`/web/students/generateLogin/${name}`)
					.then(res => {
						this.tempLogin = res.data;
					})
					.catch(err => {});
			},
			generatePass() {
				axios.get('/web/students/generatePassword')
					.then(res => {
						this.tempPass = res.data;
					})
					.catch(err => {});
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/students`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/students';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
			}
		}
	}
</script>