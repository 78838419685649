<template>
    <div>
        <app-page-header
            title="Escolas"
            icon="fa fa-university"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Escolas' },
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            entity="schools"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/schools',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/schools/create',
                },
                show: {
                    allow: allows.show,
                    callback: onShow,
                },
                edit: {
                    allow: allows.edit,
                    url: '/schools/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*',
                },
                classrooms: {
                    allow: true,
                    url: '/classrooms?school_id=*'
                },
            }"
            :filters="[
                {
                    name: 'director_name',
                    type: 'input',
                    input: 'text', 
                    label: 'Nome do Diretor',
                    index: 5,
                },
                {
                    name: 'state_id',
                    type: 'select',
                    label: 'Estado',
                    options: states,
                    liveSearch: true,
                    callback: onChangeState,
                },
                {
                    name: 'city_id',
                    type: 'select',
                    label: '-',
                    index: 4,
                    liveSearch: true,
                    options: [],
                }
            ]"
        ></app-datatable>

        <app-school-show ref="schoolShow"></app-school-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';
    import SchoolShow from './SchoolShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
            'app-school-show': SchoolShow,
        },
        data() {
            return {
                states: [],
            }
        },
        props: {
            allows: Object,
            columns: Array,
            params: Object | Array,
        },
        mounted() {
            this.loadStates();

            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
        methods: {
            loadStates() {
                axios.get('/web/states/list?toTags=true')
                    .then(res => {
                        this.states = res.data;
                    })
                    .catch(err => {});
            },
            onShow(id) {
                this.$refs.schoolShow.open(id);
            },
            onChangeState(state) {
                axios.get(`/web/cities/findByState/${state.value}?toTags=true`)
                    .then(res => {
                        this.$refs.dataTable.setNewOptions('city_id', res.data);
                    })
                    .catch(err => {});
            }
        }
    }
</script>