<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg with-thumb" role="document">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <img 
						:src="data.thumb"
						class="thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow"
					>
                    <h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
                </div>
                <div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="row">
                                <div class="col-12 col-xl-4 col-md-6">
									<p><strong>ID</strong></p>
									<p>{{ data.id }}</p>
								</div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Nome</strong></p>
                                    <p>{{ data.name }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>E-mail</strong></p>
                                    <p>{{ data.user.email }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Telefone</strong></p>
                                    <p v-if="data.phone">
										{{ data.phone | VMask('(##) ####-####') }}
									</p>
									<p v-if="!data.phone">-</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Celular</strong></p>
                                    <p v-if="data.cellphone">
										{{ data.cellphone | VMask('(##) #####-####') }}
									</p>
									<p v-if="!data.cellphone">-</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Usuário</strong></p>
                                    <p>{{ data.user.login }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Setor</strong></p>
                                    <p>{{ data.user.profile.title }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Status</strong></p>
                                    <p>{{ data.active_text }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Cadastrado em</strong></p>
                                    <p>
                                        {{ new Date(data.user.created_at).toLocaleDateString() }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modal: HTMLElement,
                title: '',
                loading: true,
                data: {}
            }
        },
        props: {
            defaultTitle: { type: String, default: 'Gerente' }
        },
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {
                this.loading = true;
                this.modal = $(this.$refs.modal);
                this.title = 'Carregando...';
                this.modal.modal('show');

                axios.get(`/web/managers/${id}`)
                    .then(res => {
                        this.loading = false;
                        this.data = res.data;
                        this.title = `${this.defaultTitle}`;
                    })
                    .catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			},
			goBack() {
				window.history.go(-1);
			}
        }
    }
</script>