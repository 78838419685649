<template>
	<div
		:id="containerId"
		:class="containerClass"
	>
		<div class="form-group" :class="{ 'required' : required }">
			<label v-if="label" :for="name">
				{{ label }}
				<a
					v-if="help"
					:id="id"
					tabindex="0"
					class="btn btn-outline-info btn-circle btn-circle-xsm btn-help"
					data-toggle="popover"
					data-trigger="focus"
					role="button"
					:data-content="help.text"
				>
					<i class="fa fa-question"></i>
				</a>
			</label>
			<input
				:type="type"
				:name="name"
				:class="[{ 'is-invalid' : error }, className]"
				:pattern="pattern"
				:minlength="minLength"
				:maxlength="maxLength"
				:title="title"
				:disabled="disabled"
				:required="required"
				:readonly="readonly"
				:placeholder="placeholder"
				v-model="input"
				v-mask="mask"
				@input="onInput"
				@change="onChange"
			>
			<div v-if="error" class="invalid-feedback">
				{{ error }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				input: ''
			};
		},
		props: {
			id: String,
			placeholder: String,
			type: { type: String, default: 'text' },
			className: { type: String, default: 'form-control' },
			label: String,
			name: String,
			value: String | Number,
			pattern: String,
			minLength: Number,
			maxLength: Number,
			title: String,
			required: Boolean,
			readonly: Boolean,
			disabled: { type: Boolean, default: false },
			containerId: String,
			containerClass: { type: String, default: 'col-12 col-xl-4 col-md-6' },
			help: Object,
			error: String,
			mask: { type: String | Array, default: '' },
			callback: Function
		},
		mounted() {
			this.input = this.value ?? '';
		},
		watch: {
			value(newValue, oldValue) {
				this.input = newValue;
			}
		},
		methods: {
			onInput(e) {
				if (this.pattern) {
					let re = RegExp(`^${this.pattern}+$`);
					
					if (!re.test(this.input)) {
						this.input = this.input.substring(0, this.input.length - 1);
					}
				}
			},
			onChange(e) {
				if (this.callback) {
					this.callback(this.input, e);
				}
			}
		}
	}
</script>
