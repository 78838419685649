var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Relatório Detalhado","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '/students/report', title: 'Relatório de Alunos' },
                { href: '', title: 'Relatório Detalhado' },
            ]}}),_vm._v(" "),(_vm.student)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tite-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Aluno:")]),_vm._v(" "+_vm._s(_vm.student.name)+"\n                            ")]),_vm._v(" "),(_vm.student.data && _vm.student.data.classroom)?_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Sala:")]),_vm._v(" "+_vm._s(_vm.student.data.classroom.name)+"\n                            ")]):_vm._e(),_vm._v(" "),(_vm.student.data && _vm.student.data.school)?_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Escola:")]),_vm._v(" "+_vm._s(_vm.student.data.school.name)+"\n                            ")]):_vm._e(),_vm._v(" "),(_vm.student.responsible)?_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Responsável:")]),_vm._v(" "+_vm._s(_vm.student.responsible.name)+"\n                            ")]):_vm._e(),_vm._v(" "),(!_vm.student.data)?_c('div',{staticClass:"col-12 mt-2"},[_c('div',{staticClass:"alert alert-warning text-center m-0"},[_vm._v("\n                                    Aluno sem registro para esse ano!\n                                ")])]):_vm._e()])])])])]):_vm._e(),_vm._v(" "),_c('app-filter',{attrs:{"filters":[
                {
                    icon: 'fa fa-calendar',
                    name: 'year',
                    value: _vm.year,
                    type: 'input',
                    datepicker: true,
                },
                {
                    icon: 'fa fa-th-list',
                    name: 'module_id',
                    data: _vm.moduleList,
                    value: _vm.module_id,
                    class: 'col-12 col-xl-4 col-md-6'
                }
            ],"onChangeCallback":_vm.onFilter}}),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.modules),function(module){return _c('app-module-box',{key:module.id,attrs:{"module":module,"openChart":_vm.openChart}})}),1),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tite-body"},[_c('app-link',{attrs:{"type":"back","url":_vm.backUrl}})],1)])])]),_vm._v(" "),_c('app-latest-scores-chart',{ref:"chart",attrs:{"student-id":_vm.student.id}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }