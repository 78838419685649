<template>
    <div v-if="school">
        <app-page-header
            icon="fa fa-university"
            :title="'Editar Escola: ' + id"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '/schools', title: 'Escolas' },
                { href: '', title: 'Editar' }
            ]"
        ></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
                                <div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb" 
                                        class="thumb img-thumbnail img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Editar Logo da Escola"
                                        :outline="false"
                                        :withTitle="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                    <input
                                        type="hidden"
                                        name="removeThumb"
                                        :value="removeThumb"
                                    >
                                </div>
                                <app-input
                                    title="mínimo 6 caracters"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="65"
                                    :minLength="6"
                                    :required="true"
                                    :value="school.name"
                                ></app-input>
                                <app-input
                                    title="mínimo 3 caracters"
                                    name="company_name"
                                    label="Razão Social"
                                    placeholder="Razão Social"
                                    :maxLength="65"
                                    :minLength="3"
                                    :value="school.company_name"
                                ></app-input>
                                <app-input
                                    title="mínimo 18 caracters"
                                    name="cnpj"
                                    label="CNPJ"
                                    placeholder="CNPJ"
                                    v-mask="'##.###.###/####-##'"
                                    :maxLength="18"
                                    :minLength="18"
                                    :value="school.cnpj"
                                ></app-input>
                                <app-input
                                    name="email"
                                    label="E-mail"
                                    placeholder="E-mail"
                                    :maxLength="300"
                                    :required="true"
                                    type="email"
                                    :value="school.email"
                                ></app-input>
                                <app-input
                                    title="mínimo 14 caracters"
                                    name="phone"
                                    label="Telefone"
                                    placeholder="Telefone"
                                    v-mask="'(##) ####-####'"
                                    :maxLength="15"
                                    :minLength="14"
                                    :required="true"
                                    :value="school.phone"
                                ></app-input>
                                <app-toggle
                                    v-if="isManager"
									label="Status"
									name="active"
									:value="school.active"
								></app-toggle>
                            </div>
                            <hr>
                            <fieldset>
                                <legend>Endereço</legend>
                                <div class="row">
                                    <app-input
                                        title="mínimo 9 caracters"
                                        name="zip_code"
                                        label="CEP"
                                        placeholder="CEP"
                                        containerClass="col-12 col-xl-2 col-md-3"
                                        v-mask="'#####-###'"
                                        :maxLength="9"
                                        :minLength="9"
                                        :required="true"
                                        :callback="this.loadAdressByZipCode"
                                        :value="school.zip_code"
                                    ></app-input>
                                    <app-select
                                        name="state_id"
                                        label="Estado"
                                        title="Estado"
                                        :required="true"
                                        :options="states"
                                        :callback="loadCitiesByState"
                                        containerClass="col-12 col-xl-2 col-md-3"
                                        :value="addressTemp.state"
                                    ></app-select>
                                    <app-select
                                        name="city_id"
                                        label="Cidade"
                                        title="Cidade"
                                        :required="true"
                                        :options="cities"
                                        :data-live-search="true"
                                        :value="addressTemp.city"
                                    ></app-select>
                                    <app-input
                                        name="address"
                                        label="Rua"
                                        placeholder="Rua"
                                        :maxLength="100"
                                        :minLength="2"
                                        :required="true"
                                        :value="addressTemp.street"
                                    ></app-input>
                                </div>
                            </fieldset>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>

                        <app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Textarea from '../forms/TextareaComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import ImageSelect from '../ImageSelectComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-textarea': Textarea,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
            'app-toggle': Toggle,
        },
        data() {
            return {
                school: null,
                DEFAULT_IMAGE: '/assets/img/default_thumbs/school.png',
                tempImage: '',
                states: [],
                cities: [],
                addressTemp: {
                    state: '',
                    city: '',
                    street: '',
                },
                removeThumb: 0
            }
        },
        props: {
            id: String,
            isManager: Boolean,
			backUrl: String,
		},
        mounted() {
			this.tempImage = this.DEFAULT_IMAGE;
            this.load(this.id);
		},
        methods: {
            load(id) {
				axios.get(`/web/schools/${id}`)
                    .then(res => {
                        this.school = res.data;
                        this.tempImage = this.school.thumb;

                        this.addressTemp.street = this.school.address;

                        this.loadStates();
                    })
                    .catch(err => {});
			},
            openEditImage() {
                this.$refs.imageSelect.open();
            },
			imageLoaded(image) {
                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
            loadStates() {
                axios.get(`/web/states/list?toTags=true`)
                    .then(res => {
                        this.states = res.data;
                        this.addressTemp.state = this.school.city.state_id;

                        this.loadCitiesByState(this.school.city.state_id);
                    })
                    .catch(err => {});
            },
            loadCitiesByState(state_id, cityName = null) {
                axios.get(`/web/cities/findByState/${state_id}?toTags=true`)
                    .then(async res => {
                        this.cities = res.data;

                        if (cityName) {
                            let city = this.cities.find(o => {
                                return o.label == cityName;
                            });

                            this.addressTemp.city = city.value;
                        } else {
                            this.addressTemp.city = this.school.city.id;
                        }

                        await Help.delay(300);

                        $('.selectpicker').selectpicker('destroy');
                        $('.selectpicker').selectpicker('refresh');
                    })
                    .catch(err => {});
            },
            loadAdressByZipCode(zipCode) {
                if (zipCode.length < 9) return;
                zipCode = zipCode.replace(/\D/g, '');
                
                axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
                    .then(res => {
                        let state = this.states.find(o => {
                            return o.label == res.data.uf;
                        });
                        
                        this.addressTemp.state = state.value;
                        this.loadCitiesByState(state.value, res.data.localidade);
                        this.addressTemp.street = res.data.logradouro + ',';
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);

				formData.append('_method', 'PATCH');
				formData.append('thumb', this.$refs.imageSelect.file);

				let validate = this.validateForm(this.$refs.form);

				if (validate != '') {
					Flash('error', validate);
				} else {
					axios.post(`/web/schools/${this.school.id}`, formData)
						.then(res => {
							Swal.fire({
								title: 'Atualizado com sucesso!',
								type: 'success'
							})
							.then(result => {
								window.location.href = '/schools';
							});
						})
						.catch(err => {
							Flash('error', 'Erro ao tentar atualizar registro!');
						});
				}
            }
        }
    }
</script>