<template>
    <div>
        <app-page-header
			icon="fa fa-cog"
			title="Cadastrar Recurso"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/resources', title: 'Recursos' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
							<div class="row">
                                <app-input
                                    name="controller"
                                    label="Nome do Controlador"
                                    placeholder="Nome do Controlador"
                                    :required="true"
                                ></app-input>
                                <app-input
                                    name="action"
                                    label="Ação"
                                    placeholder="Ação"
                                    :required="true"
                                ></app-input>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
        },
        props: {
            backUrl: String,
        },
        methods: {
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/resources`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/resources';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>