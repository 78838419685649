<template>
    <div>
        <app-page-header
            icon="fa fa-play-circle"
            title="Animações"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Animações' },
            ]"
        ></app-page-header>
        
        <app-datatable
            entity="animations"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/animations',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/animations/create',
                },
                show: {
                    allow: allows.show,
                    callback: onShow,
                },
                edit: {
                    allow: allows.edit,
                    url: '/animations/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*',
                },
                preview: {
                    allow: allows.preview,
                    callback: onPreview,
                },
                indicate: {
                    allow: allows.indicate,
                    url: '/indications/create?activity_id=@',
                },
            }"
        ></app-datatable>
        
        <app-animation-show ref="animationShow"></app-animation-show>
        <app-animation-preview ref="animationPreview"></app-animation-preview>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
	import AnimationShow from './AnimationShowComponent';
    import AnimationPreview from './AnimationPreviewComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
			'app-animation-show': AnimationShow,
            'app-animation-preview': AnimationPreview,
		},
		props: {
            params: Object | Array,
			allows: Object,
			columns: Array,
		},
        mounted() {
            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
		methods: {
			onShow(id) {
				this.$refs.animationShow.open(id);
			},
            onPreview(id) {
                this.$refs.animationPreview.load(id);
            }
		}
    }
</script>