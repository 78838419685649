<template>
    <div>
        <app-page-header
            icon="fa fa-graduation-cap"
            title="Relatório de Salas"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Relatório de Salas' },
            ]"
        ></app-page-header>

        <app-filter
            v-if="filters.length"
            :filters="filters"
            :onChangeCallback="onFilter"
        ></app-filter>

        <app-datatable
            v-if="school_id"
            ref="dataTable"
            :options="{
                print: true,
                btFilter: false,
                columnsToPrint: [0,1,2],
                ajax: {
                    url: '/report/classrooms'
                },
                columns: columns,
                searchCols: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    { search: this.year },
                    { search: this.school_id },
                ],
            }"
            :actions="{
                student: {
                    allow: allows.student,
                    url: '/students/report?classroom_id=*'
                },
            }"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-filter': Filter,
			'app-datatable': DataTable,
		},
        data() {
            return {
                year: '',
                school_id: null,
                filters: [
                    {
                        icon: 'fa fa-calendar',
                        name: 'year',
                        value: new Date().getFullYear(),
                        type: 'input',
                        datepicker: true,
                        index: 5
                    }
                ]
            };
        },
        props: {
            schoolId: Number,
            columns: Array,
            allows: Object,
        },
        mounted() {
            this.loadSchools();
        },
        methods: {
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(async res => {
                        let schoolSelected = (this.schoolId) ? this.schoolId : res.data[0].value;
                        let textPopover = 'Filtra e exibe o desempenho com e sem alunos ';
                        textPopover += 'transferidos,  ou que não pertencem mais a sala';

                        this.filters.push({
                            icon: 'fa fa-university',
                            name: 'school_id',
                            data: res.data,
                            index: 6,
                            value: schoolSelected,
                            class: 'col-12 col-xl-4 col-md-6'
                        });
                        
                        this.filters.push({
                            type: 'toggle',
                            name: 'toggle-student-active',
                            class: 'col-3',
                            value: true,
                            icon: 'fa fa-question',
                            popover_content: textPopover,
                        });

                        this.school_id = schoolSelected;
                        await Help.delay(300);
                        window.config.loadToggle();
                    })
                    .catch(err => {});
            },
            onFilter(data) {
                if (data[0].name == 'toggle-student-active') {
                    let url = data[0].value 
                        ? `/report/classrooms`
                        : `/report/classrooms?students_active=1`
                    
                    this.$refs.dataTable.setUrl(url);
                }
                
                this.year = data.filter(o => o.name == 'year')[0].value;
                
                if (this.school_id) {
                    this.$refs.dataTable.onChangeSideFilter(data);
                }
            }
        }
    }
</script>