<template>
    <div
        class="col-12 card mb-1"
        :class="'alert-' + (own ? 'info' : 'success')"
    >
        <div v-if="!own" class="media p-1">
            <img :src="getImage()" class="mr-3 ticket-img ticket-img-left">
            <div class="media-body">
                <h6 class="mt-0 mb-1">{{ getName() }}</h6>
                <span class="text-muted ticket-date">
                    POSTADO EM: {{ new Date(message.created_at).toLocaleString() }}
                </span>
            </div>
        </div>
        <div v-else class="media p-1">
            <div class="media-body text-right">
                <h6 class="mt-0">{{ getName() }}</h6>
                <span class="text-muted ticket-date">
                    POSTADO EM: {{ new Date(message.created_at).toLocaleString() }}
                </span>
            </div>
            <img :src="getImage()" class="ml-3 ticket-img ticket-img-right">
        </div>
        <div class="ticket-text rounded mb-1 p-2">
            {{ message.message }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            own: Boolean,
            message: Object,
        },
        methods: {
            getName() {
                return this.message.user ? this.message.user.name : '-';
            },
            getImage() {
                return this.message.user
                    ? this.message.user.image
                    : '/assets/img/default_thumbs/user.png';
            }
        }
    }
</script>