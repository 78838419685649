<template>
    <div class="col-12 col-sm-6 p-2">
        <div
            v-if="activity"
            :class="[
                'card card-light card-activity',
                activity.activity_type.code
            ]"
        >
            <div
                :class="[
                    'card-header d-flex justify-content-between p-2',
                    activity.activity_type.code + '-color'
                ]"
                data-toggle="tooltip"
                data-placement="top"
                :title="activity.title_full"
            >
                <i :class="'fa fa-2x fa-' + icon"></i>
                <div>
                    <img
                        v-for="star in stars"
                        src="/assets/img/icon_star.png"
                        :class="star"
                    >
                </div>
            </div>
            <div class="card-body">
                <div
                    class="card-text text-center text-truncate px-2"
                    :title="activity.type.title"
                    data-toggle="tooltip"
                >
                    {{ activity.type.title }}
                </div>
            </div>
            <div class="card-footer d-flex justify-content-between align-items-center p-2">
                <span>{{ activity.activity_type.text }} {{ activity.scores_count }}x</span>
                <button
                    :class="[
                        'btn btn-outline-info btn-circle',
                        'btn-circle-sm btn-table'
                    ]"
                    @click="openChart(activity.id)"
                    title="Histórico"
                    data-toggle="tooltip"
                >
                    <i class="fa fa-line-chart"></i>
                </button>
            </div>
        </div>
        <div
            v-else
            class="card card-activity ghost"
        >
            <div class="card-header" :style="{ height: '47px' }">&nbsp;</div>
            <div class="card-body">&nbsp;</div>
            <div class="card-footer" :style="{ height: '52px' }">&nbsp;</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                icon: '',
                stars: [],
            }
        },
        props: {
            activity: Object | null,
            openChart: Function,
        },
        mounted() {
            if (this.activity) {
                this.icon = config.getIcon(this.activity.activity_type.code);
                this.stars = this.getStars();
                config.loadTooltip();
            }
        },
        methods: {
            getStars() {
                let stars = [];

                for (let i in [1,2,3]) {
                    stars.push(
                        (this.activity.score && i <= this.activity.score.stars) ? '' : 'disabled'
                    );
                }

                return stars;
            }
        }
    }
</script>