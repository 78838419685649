<template>
    <li class="dropdown">
        <a
            class="app-nav__item"
            href="#"
            data-toggle="dropdown"
            aria-label="Open Profile Menu"
            title="Configurações do usuário"
        >
            <i class="fa fa-user fa-lg"></i>
        </a>
        <ul class="dropdown-menu settings-menu dropdown-menu-right">
            <li>
                <a
					class="dropdown-item bg-light"
					href="javascript:" 
					title="Editar Perfil"
					@click="onEdit"
				>
					<i class="fa fa-user fa-lg"></i>
                    Editar Perfil
				</a>
            </li>
            <li>
                <a
					class="dropdown-item bg-light"
					href="javascript:" 
					title="Sair"
					@click="onLogout"
				>
					<i class="fa fa-sign-out fa-lg"></i>
                    Sair
				</a>
            </li>
        </ul>
    </li>
</template>

<script>
    export default {
        props: {
            userId: Number,
        },
        methods: {
            onEdit() {
                window.location.href = `/users/${this.userId}/edit`;
            },
            onLogout() {
				axios.post('/web/logout')
					.then(res => {
						window.location.href = '/';
					})
					.catch(err => {
						Flash('error', 'Não foi possível sair do sistema, tente novamente!');
					});
			}
        }
    }
</script>