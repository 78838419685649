<template>
    <div>
        <app-page-header
            icon="fa fa-tag"
            title="Tags"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Tags' },
            ]"
        ></app-page-header>

        <app-datatable
            entity="tags"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="3"
            :options="{
                ajax: {
                    url: '/web/tags',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/tags/create',
                },
                edit: {
                    allow: allows.edit,
                    url: '/tags/*/edit',
                },
            }"
        ></app-datatable>
    </div>    
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
        },
        props: {
            columns: Array,
            allows: Object,
        }
    }
</script>