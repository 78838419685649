<template>
    <div>
        <app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
                { href: '', title: 'Painel' },
            ]"
        ></app-page-header>

        <div class="row">
            <app-jumbotron :name="userName"></app-jumbotron>
        </div>

        <div class="row">
            <app-widget
                v-if="modules"
                type="warning"
                icon="fa fa-th-list"
                title="Módulos"
                :value="modules.total"
                :link="{
                    label: 'Ranking de uso',
                    url: '/modules/report'
                }"
                :data="[
                    { label: 'Ativos', value: modules.enabled },
                    { label: 'Inativos', value: modules.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="activities"
                type="warning"
                icon="fa fa-archive"
                title="Atividades"
                :value="activities.total"
                :link="{
                    label: 'Ranking de uso',
                    url: '/activities/report'
                }"
                :data="[
                    { label: 'Ativos', value: activities.enabled },
                    { label: 'Inativos', value: activities.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="schools"
                type="success"
                icon="fa fa-university"
                title="Escolas"
                :value="schools.total"
                :link="{
                    label: 'Desempenho',
                    url: '/schools/report'
                }"
                :data="[
                    { label: 'Ativos', value: schools.enabled },
                    { label: 'Inativos', value: schools.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="classrooms"
                type="success"
                icon="fa fa-graduation-cap"
                title="Salas"
                :value="classrooms.total"
                :link="{
                    label: 'Desempenho',
                    url: '/classrooms/report'
                }"
                :data="[
                    { label: 'Ativos', value: classrooms.enabled },
                    { label: 'Inativos', value: classrooms.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="directors"
                type="info"
                icon="fa fa-user"
                title="Diretores"
                :value="directors.total"
                :link="{
                    label: 'Acessos (' + logs.directors + ')',
                }"
                :data="[
                    { label: 'Ativos', value: directors.enabled },
                    { label: 'Inativos', value: directors.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="teachers"
                type="info"
                icon="fa fa-user"
                title="Professores"
                :value="teachers.total"
                :link="{
                    label: 'Acessos (' + logs.teachers + ')',
                }"
                :data="[
                    { label: 'Ativos', value: teachers.enabled },
                    { label: 'Inativos', value: teachers.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="responsibles"
                type="info"
                icon="fa fa-user"
                title="Responsáveis"
                :value="responsibles.total"
                :link="{
                    label: 'Acessos (' + logs.responsibles + ')',
                }"
                :data="[
                    { label: 'Ativos', value: responsibles.enabled },
                    { label: 'Inativos', value: responsibles.disabled },
                ]"
            ></app-widget>
            <app-widget
                v-if="students"
                type="info"
                icon="fa fa-user"
                title="Alunos"
                :value="students.total"
                :link="{
                    label: 'Acessos (' + logs.students + ')',
                    url: 'students/report'
                }"
                :data="[
                    { label: 'Ativos', value: students.enabled },
                    { label: 'Inativos', value: students.disabled },
                ]"
            ></app-widget>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Jumbotron from '../JumbotronComponent';
    import Widget from './WidgetComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-jumbotron': Jumbotron,
            'app-widget': Widget,
		},
        data() {
            return {
                modules: null,
                activities: null,
                schools: null,
                classrooms: null,
                directors: null,
                teachers: null,
                responsibles: null,
                students: null,
                logs: null,
            }
        },
        props: {
            userName: String,
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                axios.get('/web/dashboards')
                    .then(res => {
                        this.modules = res.data.modules;
                        this.activities = res.data.activities;
                        this.schools = res.data.schools;
                        this.classrooms = res.data.classrooms;
                        this.directors = res.data.directors;
                        this.teachers = res.data.teachers;
                        this.responsibles = res.data.responsibles;
                        this.students = res.data.students;
                        this.logs = res.data.logs;
                    })
                    .catch(err => {});
            }
        }
    }
</script>