var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_c('i',{staticClass:"fa fa-th-list"}),_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.title)+"\n\t\t\t\t")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row content"},[(_vm.loading)?_c('div',{staticClass:"col-12 text-center"},[_c('img',{attrs:{"src":"/assets/img/loading.svg"}})]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.name))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(2),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.year.title))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-3 col-md-6"},[_vm._m(3),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.data.active_text))])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('fieldset',[_c('legend',{staticClass:"h6"},[_vm._v("Atividades")]),_vm._v(" "),_c('div',{staticClass:"list-group"},_vm._l((_vm.data.activities),function(activity){return _c('a',{class:[
												'list-group-item list-group-item-action',
												'd-flex align-items-center',
												'justify-content-between'
											],attrs:{"href":'/'
												+ activity.activity_type.code
												+ 's?id='
												+ activity.type.id}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(activity.title_full)+"\n\t\t\t\t\t\t\t\t\t\t\t"),_c('span',{class:[
													'badge badge-pill badge-info',
													'float-right p-2'
												]},[_c('i',{staticClass:"fa fa-eye"})])])}),0)])])])]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Nome")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Ano")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Status")])])
}]

export { render, staticRenderFns }