var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-thumb-tack","title":'Relatório da Indicação: ' + _vm.indicationId,"breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '/indications/report', title: 'Relatório de Indicações' },
            { href: '', title: 'Relatório Detalhado' },
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"options":{
            print: true,
            btFilter: false,
            ajax: {
                url: '/report/students/indication/' + _vm.indicationId
            },
            columns: _vm.columns,
        },"actions":{}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }