<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-file-input
                        ref="thumb"
                        name="thumb"
                        containerClass="col-12"
                        accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp"
                        :callback="onChange"
                    ></app-file-input>
                </div>
                <div class="modal-footer">
                    <app-button
                        icon="trash"
                        color="danger"
                        title="Remover imagem"
                        type="button"
                        :callback="onRemove"
                    ></app-button>
                    <app-button
                        title="Selecionar imagem"
                        type="button"
                        :callback="onSave"
                    ></app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FileInput from '../components/forms/FileInputComponent';
    import Button from '../components/forms/ButtonComponent';

    export default {
        components: {
            'app-file-input': FileInput,
            'app-button': Button,
        },
        data() {
            return {
                modal: HTMLElement,
                thumb: HTMLElement,
                file: '',
            }
        },
        props: {
            title: { type: String, default: 'Gerenciar Imagem' },
            imageLoaded: Function
        },
        mounted() {
            this.modal = $(this.$refs.modal);
            this.thumb = this.$refs.thumb;
        },
        methods: {
            open() {
                this.modal.modal('show');
            },
            onChange(file) {
                this.file = file;
            },
            onRemove() {
                this.thumb.reset();
                this.file = '';
                this.imageLoaded(null);
                this.modal.modal('hide');
            },
            onSave() {
                if (this.file != '') {
                    this.imageLoaded(this.file);
                    this.modal.modal('hide');
                } else {
                    Flash('warning', 'Seleciona uma imagem!');
                }
            }
        }
    }
</script>