export default class ProfileType {

    static MANAGER = new ProfileType('manager');
    static TEACHER = new ProfileType('teacher');
    static DIRECTOR = new ProfileType('director');

    static getByName(name) {
        for (let propName of Object.keys(ProfileType)) {
            let prop = ProfileType[propName];

            if (prop.name == name) {
                return prop;
            }
        }

        return ProfileType.MANAGER;
    }

    constructor(name) {
        this.name = name;
    }

}
