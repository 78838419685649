<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Professores"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Professores' }
            ]"
        ></app-page-header>
        
        <app-datatable
            ref="dataTable"
            entity="teachers"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/teachers'
                },
                columns: columns,
                searchCols: initFilter
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/teachers/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: allows.edit,
                    url: '/teachers/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                }
            }"
            :filters="filters"
        ></app-datatable>

        <app-teacher-show :viewSchool="allows.viewSchool" ref="teacherShow"></app-teacher-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import TeacherShow from './TeacherShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
			'app-datatable': DataTable,
            'app-teacher-show': TeacherShow
        },
        data() {
            return {
                filters: [
                    {
                        name: 'state_id',
                        type: 'select',
                        label: 'Estado',
                        options: [],
                        callback: this.loadCities,
                    },
                    {
                        name: 'city_id',
                        type: 'select',
                        label: 'Cidade',
                        liveSearch: true,
                        options: [],
                        callback: this.loadSchools,
                    },
                    {
                        name: 'school_id',
                        type: 'select',
                        label: 'Escola',
                        liveSearch: true,
                        index: 4,
                        options: []
                    }
                ],
                initFilter: []
            }
        },
        props: {
            allows: Object,
            columns: Array,
            isDirector: Boolean,
            params: Object | Array,
        },
        mounted() {
            this.loadInitFilter();

            if (this.isDirector) {
                this.filters = [];
            } else {
                this.loadStates();
            }

            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
        methods: {
            onShow(id) {
                this.$refs.teacherShow.open(id);
            },
            loadInitFilter() {
                loop1:
                for (let i = 0; i < this.columns.length; i++) {
                    let col = this.columns[i].name;
                loop2:
                    for (let param of Object.keys(this.params)) {
                        if (param == 'id') continue;

                        if (String(col).indexOf(param) != -1) {
                            this.initFilter.push({ 'search': this.params[param] });
                            continue loop1;
                        }
                    }

                    this.initFilter.push(null);
                }
            },
            loadStates() {
                axios.get('/web/states/list?toTags=true')
                    .then(res => {
                        this.filters[0].options = res.data;
                    })
                    .catch(err => {});
            },
            loadCities(e) {
                axios.get(`/web/cities/findByState/${e.value}?toTags=true`)
                    .then(res => {
                        this.$refs.dataTable.setNewOptions('city_id', res.data);
                    })
                    .catch(err => {});
            },
            loadSchools(e) {
                axios.get(`/web/schools/findByCity/${e.value}?toTags=true`)
                    .then(res => {
                        this.$refs.dataTable.setNewOptions('school_id', res.data);
                    })
                    .catch(err => {});
            },
        }
    }
</script>