<template>
	<div v-if="book">
		<app-page-header
            :title="'Editar Livro: ' + id"
            icon="fa fa-book"
            :breadcrumbs="[
				{ href: '/', title: 'Painel' },
                { href: '/books', title: 'Livros' },
                { href: '', title: 'Editar' }
            ]"
        ></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
							<div class="row">
								<div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb" 
                                        class="thumb-activities img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Inserir Imagem"
                                        :outline="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                    <input
                                        type="hidden"
                                        name="removeThumb"
                                        :value="removeThumb"
                                    >
                                </div>
								<app-input
                                    title="mínimo 2 caracters"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="65"
                                    :minLength="2"
                                    :required="true"
                                    :value="book.title"
                                ></app-input>
                                <app-input
                                    title="mínimo 2 caracters"
                                    label="Assunto"
                                    name="subject"
                                    placeholder="Assunto"
                                    :maxLength="65"
                                    :minLength="2"
                                    :required="true"
                                    :value="book.subject"
                                ></app-input>
								<app-textarea
                                    name="description"
                                    label="Descrição"
                                    placeholder="Descrição"
                                    :maxLength="300"
                                    resize="none"
                                    :value="book.description"
                                ></app-textarea>
								<app-toggle
									label="Status"
									name="active"
									:value="book.active"
								></app-toggle>
							</div>
							<div class="row">
                                <app-input-tags
                                    ref="tags"
                                    name="tags"
                                    label="Tags"
                                    urlData="/web/tags/list"
                                    :fields="{ value: 'value', text: 'label' }"
                                    :values="book.tags"
                                ></app-input-tags>
                            </div>
							<div v-if="editFiles">
								<app-file-input
									label="Arquivos do Livro (.zip)"
                                    name="path"
									containerClass="col-12 col-md-6"
                                    :required="false"
									:callback="onfileSelected"
								></app-file-input>
							</div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
									<app-link
										type="edit"
										title="Cadastrar arquivos do livro"
										:onClick="editFilesToggle"
									></app-link>
                                </div>
                            </div>
						</form>

						<app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
    import Input from '../forms/InputComponent.vue';
    import Select from '../forms/SelectComponent.vue';
    import Textarea from '../forms/TextareaComponent.vue';
    import Button from '../forms/ButtonComponent.vue';
    import Link from '../forms/LinkComponent.vue';
    import ImageSelect from '../ImageSelectComponent.vue';
    import InputTags from '../forms/InputTagsComponent.vue';
    import Toggle from '../forms/ToggleComponent';
	import FileInput from '../forms/FileInputComponent.vue';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-input': Input,
            'app-select': Select,
            'app-textarea': Textarea,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
            'app-input-tags': InputTags,
            'app-toggle': Toggle,
			'app-file-input': FileInput,
		},
		data() {
			return {
				book: null,
				DEFAULT_IMAGE: '/assets/img/default_thumbs/book.png',
                tempImage: '',
                removeThumb: 0,
				editFiles: false,
				file: null,
			}
		},
		props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.tempImage = this.DEFAULT_IMAGE;
            this.load(this.id);
        },
		methods: {
			load(id) {
				axios.get(`/web/books/${id}`)
                    .then(res => {
                        this.book = res.data;
                        this.tempImage = this.book.thumb;
                    })
                    .catch(err => {});
			},
			editFilesToggle() {
				this.editFiles = !this.editFiles;
			},
			openEditImage() {
                this.$refs.imageSelect.open();
            },
            imageLoaded(image) {
                this.removeThumb = (image != null) ? 0 : 1;

                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;                    
                }
            },
			onfileSelected(file) {
				this.file = file;
			},
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
					formData.append('_method', 'PATCH');
                    formData.append('thumb', this.$refs.imageSelect.file);

					if (this.editFiles) {
                    	formData.append('path', this.file);
					}

                    for (let tag of this.$refs.tags.selectedData) {
                        formData.append('tags[]', tag.value);
                    }

                    axios.post(`/web/books/${this.book.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/books';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
			}
		}
    }
</script>