<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-music"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
					<div class="row content">
						<div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
                                <div class="col-12 col-xl-3 col-md-6">
									<img :src="data.thumb" class="img-rounded img-fluid">
								</div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Título</strong></p>
									<p>{{ data.title }}</p>
								</div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Assunto</strong></p>
									<p>{{ data.subject }}</p>
								</div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Url</strong></p>
									<p>{{ data.path }}</p>
								</div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Status</strong></p>
									<p>{{ data.active_text }}</p>
								</div>
								<div v-if="!data.path" class="col-md-12">
                                    <div class="alert alert-warning">
                                        <i class="fa fa-exclamation-triangle"></i>
                                        Necessário subir url do video!
                                    </div>
                                </div>
                                <div class="col-12 col-xl-3 col-md-6">
                                    <p><strong>Descrição</strong></p>
                                    <p>{{ data.description }}</p>
                                </div>
								<div class="col-12 col-xl-3 col-md-6">
									<p><strong>Tags</strong></p>
									<p>{{ tags }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div class="modal-footer">
                    <app-link type="back" :onClick="goBack"></app-link>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-link': Link,
        },
        data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				data: {},
				tags: String,
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Cantiga' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
        methods: {
            open(id) {
                this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/web/songs/${id}`)
					.then(res => {
						this.loading = false;
						this.data = res.data;
						this.title = `${this.defaultTitle}: ${this.data.id}`;

						let output = this.data
							.tags
							.map(o => {
                            	return o.label;
                        	});
                        
                        this.tags = output.join(', ');
					})
					.catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			},
			goBack() {
				window.history.go(-1);
			}
        }
    }
</script>