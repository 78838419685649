<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Alunos"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Alunos' }
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            entity="students"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/students'
                },
                columns: columns,
                searchCols: initFilter
            }"
            :actions="{
                qrcode: {
                    allow: allows.qrcode,
                    url: '/students/print_qrcode?student_id=*'
                },
                pass: {
                    allow: allows.pass,
                    url: '/students/print_password?student_id=*',
                },
                create: {
                    allow: allows.create,
                    url: '/students/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: allows.edit,
                    url: '/students/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                },
            }"
            :filters="filters"
        ></app-datatable>

        <app-student-show :viewSchool="allows.viewSchool" ref="studentShow"></app-student-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import StudentShow from './StudentShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
			'app-datatable': DataTable,
            'app-student-show': StudentShow
        },
        data() {
            return {
                filters: [
                    {
                        name: 'school_id',
                        type: 'select',
                        label: 'Escola',
                        index: 5,
                        options: [],
                        liveSearch: true,
                        callback: this.loadClassrooms,
                    },
                    {
                        name: 'classroom_id',
                        type: 'select',
                        label: 'Sala',
                        index: 6,
                        options: [],
                        liveSearch: true,
                    },
                    {
                        name: 'responsible_id',
                        type: 'select',
                        label: 'Responsável',
                        index: 7,
                        options: [],
                        liveSearch: true,
                    },
                    {
                        name: 'type',
                        type: 'select',
                        label: 'Tipo de Aluno',
                        index: 8,
                        options: [
                            { value: 'classroom', label: 'Com Sala'},
                            { value: 'responsible', label: 'Com Responsável'},
                        ]
                    }
                ],
                initFilter: [],
            }
        },
        props: {
            allows: Object,
            columns: Array,
            profileCode: String,
            params: Object | Array,
        },
        mounted() {
            this.loadInitFilter();

            if (!['manager'].includes(this.profileCode)) {
                this.filters.splice(this.filters.length - 1, 1);
            }
            
            if (['director', 'teacher'].includes(this.profileCode)) {
                this.filters.splice(2, 1);
            } else if (['responsible'].includes(this.profileCode)) {
                this.filters = [];
            }

            if (this.profileCode != 'responsible') {
                this.loadSchools();
            }

            if (this.profileCode == 'manager') {
                this.loadResponsibles();
            }
            
            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
        methods: {
            onShow(id) {
                this.$refs.studentShow.open(id);
            },
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        let filter = this.filters.filter(f => { return f.name == 'school_id'; })[0];
                        filter.options = res.data;

                        if (res.data.length == 1) {
                            filter.value = res.data[0].value;
                            this.loadClassrooms(res.data[0], true);
                        }
                    })
                    .catch(err => {});
            },
            loadInitFilter() {
                loop1:
                for (let i = 0; i < this.columns.length; i++) {
                    let col = this.columns[i].name;
                loop2:
                    for (let param of Object.keys(this.params)) {
                        if (param == 'id') continue;

                        if (String(col).indexOf(param) != -1) {
                            this.initFilter.push({ 'search': this.params[param] });
                            continue loop1;
                        }
                    }

                    this.initFilter.push(null);
                }
            },
            loadClassrooms(e, starting = false) {
                axios.get(`/web/classrooms/findBySchool/${e.value}?toTags=true`)
                    .then(res => {
                        if (starting) {
                            let filter = this.filters
                                .filter(f => { return f.name == 'classroom_id'; })[0];
                            filter.options = res.data;
                        } else {
                            this.$refs.dataTable.setNewOptions('classroom_id', res.data);
                        }
                    })
                    .catch(err => {});
            },
            loadResponsibles() {
                axios.get('/web/responsibles/list')
                    .then(res => {
                        this.filters
                        .filter(f => { return f.name == 'responsible_id'; })[0]
                        .options = res.data;
                    })
                    .catch(err => {});
            }
        }
    }
</script>