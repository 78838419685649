<template>
    <div>
        <app-page-header
            icon="fa fa-university"
            title="Relatório de Escolas"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Relatório de Escolas' },
            ]"
        ></app-page-header>

        <app-filter
            v-if="filterReady"
            :filters="[
                {
                    icon: 'fa fa-calendar',
                    name: 'year',
                    value: year,
                    type: 'input',
                    datepicker: true,
                    index: 5,
                },
                {
                    icon: 'fa fa-th-list',
                    name: 'state_id',
                    data: states,
                    index: 6,
                    value: state_id,
                    class: 'col-12 col-xl-4 col-md-6',
                    callback: loadCities,
                },
                {
                    icon: 'fa fa-th-list',
                    name: 'city_id',
                    data: cities,
                    index: 7,
                    value: city_id,
                    class: 'col-12 col-xl-4 col-md-6',
                }
            ]"
            :onChangeCallback="onFilter"
        ></app-filter>

        <app-datatable
            v-if="filterReady"
            ref="dataTable"
            :options="{
                print: true,
                btFilter: false,
                columnsToPrint: [0,1,2,3],
                ajax: {
                    url: '/report/schools'
                },
                columns: columns,
                searchCols: [
                    null,
                    null,
                    null,
                    null,
                    null,
                    { search: this.year },
                    { search: this.state_id },
                    { search: this.city_id },
                ],
            }"
            :actions="{
                classrooms: {
                    allow: allows.classrooms,
                    url: '/classrooms/report?school_id=*'
                },
            }"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Filter from '../FilterComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-filter': Filter,
			'app-datatable': DataTable,
		},
        data() {
            return {
                year: null,
                filterReady: false,
                states: [],
                cities: [],
                state_id: null,
                city_id: null,
            };
        },
        props: {
            columns: Array,
            allows: Object,
        },
        mounted() {
            this.year = new Date().getFullYear();

            this.loadStates();
        },
        methods: {
            loadStates() {
                axios.get('/web/states/list?toTags=true')
                    .then(res => {
                        this.states = res.data;
                        
                        if (!this.state_id) {
                            this.state_id = res.data[0].value;
                        }
                        
                        this.loadCities();
                    })
                    .catch(err => {});
            },
            loadCities(data) {
                if (data) { this.state_id = data.value; }

                this.city_id = null;

                axios.get(`/web/cities/findByState/${this.state_id}?toTags=true`)
                    .then(res => {
                        this.cities = [{ label: 'Cidade', value: '' }];
                        this.cities.push(...res.data);
                        this.city_id = this.cities[0].value;

                        if (!this.filterReady) {
                            this.filterReady = true;
                        } else {
                            this.onFilter([
                                { name: 'year', value: this.year },
                                { name: 'state_id', value: this.state_id },
                                { name: 'city_id', value: this.city_id },
                            ]);
                        }
                    })
                    .catch(err => {});
            },
            onFilter(data) {
                this.year = data.filter(o => o.name == 'year')[0].value;
                
                if (this.city_id != null) {
                    this.city_id = data.filter(o => o.name == 'city_id')[0].value;

                    this.$refs.dataTable.onChangeSideFilter(data);
                }
            }
        }
    }
</script>