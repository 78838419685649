<template>
    <div class="col-12 col-xl-4 col-md-6">
        <div class="card card-light mb-2 shadow card-module-box">
            <div class="card-header p-2 d-flex align-items-center justify-content-between">
                <h6 class="m-0">{{ module.name }}</h6>
                <div
                    class="progress pull-right user-select-none"
                    :title="module.performance + '%'"
                >
                    <div
                        :class="[
                            'progress-bar progress-bar-striped',
                            'bg-' + color
                        ]"
                        role="progressbar" 
                        :aria-valuenow="module.performance"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ minWidth: module.performance + '%' }"
                    >
                        <div class="font-weight-bold">{{ module.performance }}%</div>
                    </div>
                    <div id="pct-print" style="display: none;">
                        {{ module.performance }}%
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="row p-0 m-0">
                    <app-activity-box
                        v-for="i in [0,1,2,3]"
                        :key="i"
                        :activity="module.activities[i]"
                        :openChart="openChart"
                    ></app-activity-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ActivityBox from '../activities/ActivityBoxComponent';

    export default {
        components: {
            'app-activity-box': ActivityBox,
        },
        data() {
            return {
                color: ''
            };
        },
        props: {
            module: Object,
            openChart: Function,
        },
        mounted() {
            this.color = config.getPerformanceColor(this.module.performance);
        }
    }
</script>