<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<i class="fa fa-ticket"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onCloseModal"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
                <div class="modal-body">
					<div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="ticket">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-2 mb-md-2 px-1">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <b>ID</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-control input-group-text">
                                                        {{ data.id }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2 mb-md-2 px-1">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <b>Status</b>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="form-control input-group-text"
                                                        :class="'alert-' + data.status_color"
                                                    >
                                                        {{ data.status_text }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2 mb-md-2 px-1">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <b>Setor</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-control input-group-text">
                                                        {{ data.department_text }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 mb-2 mb-md-2 px-1">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <b>Atualizado em</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-control input-group-text">
                                                        {{ new Date(data.updated_at)
                                                            .toLocaleString() }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row">
                                            <div class="col-12">Chat</div>
                                        </div>
                                    </div>
                                    <div class="card-body py-1">
                                        <div class="row">
                                            <app-message
                                                v-for="message in data.messages"
                                                :key="message.id"
                                                :own="userLoggedId == message.user_id"
                                                :message="message"
                                            ></app-message>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loading" class="modal-footer">
                    <app-link
                        v-show="transferEnabled"
                        type="edit"
                        icon="exchange"
                        title="Transferir"
                        :onClick="transfer"
                        data-dismiss="modal"
                    ></app-link>
                    <app-link
                        v-show="reopenEnabled"
                        icon="envelope-open-o"
                        title="Reabrir"
                        :onClick="reopen"
                        data-dismiss="modal"
                    ></app-link>
                    <app-link
                        v-show="!reopenEnabled"
                        icon="envelope-open-o"
                        title="Responder"
                        :onClick="reopen"
                        data-dismiss="modal"
                    ></app-link>
                    <app-link
                        v-show="!reopenEnabled"
                        type="delete"
                        icon="envelope-o"
                        title="Fechar"
                        :onClick="close"
                        data-dismiss="modal"
                    ></app-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Message from './TicketMessageComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-message': Message,
            'app-link': Link,
        },
        data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				data: {},
                transferEnabled: false,
                reopenEnabled: false,
			}
		},
		props: {
            profileId: Number,
            userLoggedId: Number,
            onTransfer: Function,
            onReopen: Function,
            onClose: Function,
			defaultTitle: { type: String, default: 'Ticket' },
		},
		mounted() {
			this.title = this.defaultTitle;
		},
        methods: {
            open(id) {
                this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/web/tickets/${id}`)
					.then(res => {
						this.loading = false;
						this.data = res.data;

						this.title = `${this.defaultTitle}: ${this.data.id}`;
                        this.transferEnabled = this.isTransferEnabled();
                        this.reopenEnabled = (this.data.status == 3);
					})
					.catch(err => {});
            },
            isTransferEnabled() {
                let id = this.profileId;
                return ([6,7,8].includes(id) || (id == 5 && this.data.department == 2));
            },
            onCloseModal() {
				this.modal.modal('hide');
			},
            transfer() {
                this.onTransfer(this.data.id);
            },
            reopen() {
                this.onReopen(this.data.id);
            },
            close() {
                this.onClose(this.data.id);
            }
        }
    }
</script>