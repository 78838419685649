<template>
    <div class="card card-custom mb-2">
        <div
            :class="[
                'card-body p-2 d-flex',
                'justify-content-between',
                'align-items-center'
            ]"
        >
            <div>{{ data.title }}</div>
            <div class="d-flex">
                <div
                    :class="[
                        'toggle d-flex',
                        'align-items-center'
                    ]"
                >
                    <label>
                        <input
                            type="checkbox"
                            name="id"
                            :value="data.id"
                            :checked="(data.active == 1)"
                            @change="onChange"
                        >
                        <span class="button-indecator"></span>
                    </label>
                </div>
                <app-link
                    v-if="editable"
                    type="create"
                    icon="pencil"
                    color="warning"
                    title="Editar"
                    :data-id="data.id"
                    :disableTitle="true"
                    :onClick="onEdit"
                ></app-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-link': Link,
        },
        props: {
            data: Object,
            onChange: Function,
            onEdit: Function,
            editable: Boolean,
        }
    }
</script>