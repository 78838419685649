var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.celebrations.length)?_c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-calendar-check-o","title":_vm.title,"breadcrumbs":[
				{ href: '/', title: 'Painel' },
				{ href: '/celebrations', title: 'Celebrações' },
				{ href: '', title: 'Configurar' },
			]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tite-body"},[_c('form',{ref:"form"},[(_vm.schools.length)?_c('input',{attrs:{"type":"hidden","name":"school_id"},domProps:{"value":_vm.school.value}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.celebrations),function(celebration){return _c('div',{staticClass:"col-12 col-xl-4 col-md-6 mb-3"},[_c('button',{staticClass:"btn form-control btn-card",class:[
											celebration.enabled
												? 'btn-success text-white'
												: 'btn-light text-muted'
										],attrs:{"type":"button"},on:{"click":function($event){celebration.enabled = !celebration.enabled}}},[_c('h6',{staticClass:"m-0"},[_vm._v(_vm._s(celebration.title))]),_vm._v(" "),_c('span',{staticClass:"pull-right"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(celebration.start_date)+" a\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(celebration.finish_date)+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),(celebration.enabled)?_c('input',{attrs:{"name":"celebrations[]","type":"hidden"},domProps:{"value":celebration.id}}):_vm._e()])])}),0),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('app-button',{attrs:{"type":"update","callback":_vm.onSubmit}}),_vm._v(" "),_c('app-link',{attrs:{"type":"back","url":_vm.backUrl}})],1)])])])])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }