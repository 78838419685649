<template>
    <div>
        <app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
                { href: '', title: 'Painel' },
            ]"
        ></app-page-header>

        <div class="row">
            <app-jumbotron :name="userName"></app-jumbotron>
        </div>

        <div class="row">
            <app-widget
                type="info"
                icon="fa fa-user"
                title="Alunos"
                :value="studentsCount"
                :link="{
                    label: 'Desempenho',
                    url: '/students/report'
                }"
            ></app-widget>
            <app-widget
                type="warning"
                icon="fa fa-thumb-tack"
                title="Indicações concluídas"
                :value="indicationsCompleted"
                :link="{
                    label: 'Histórico',
                    url: '/indications/report'
                }"
            ></app-widget>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Jumbotron from '../JumbotronComponent';
    import Widget from './WidgetComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-jumbotron': Jumbotron,
            'app-widget': Widget,
		},
        data() {
            return {
                filters: [],
                studentsCount: 0,
                indicationsCompleted: 0,
            }
        },
        props: {
            userName: String,
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                axios.get('/web/dashboards')
                    .then(res => {
                        this.studentsCount = res.data.studentsCount;
                        this.indicationsCompleted = res.data.indicationsCompleted;
                    })
                    .catch(err => {});
            }
        }
    }
</script>