<template>
    <div class="stage" :class="{ 'on' : isOpened }">
        <div ref="container" id="stage"></div>
        <a
            ref="btClose"
            class="bt-close"
            :class="{ 'on' : isOpened }"
            @click="this.destroy"
        >
            <img src="/assets/img/bt_close.png">
        </a>
    </div>
</template>

<script>
    export default {
        data: (e) => {
            return {
                isOpened: false,
                URL_PLAYER: 'https://player.vimeo.com/api/player.js',
                player: null,
            }
        },
        methods: {
            load(id) {
                console.clear();

                this.isOpened = true;

                cash(document.body).css({ "overflow-y": "hidden" });

                axios.get(`/web/songs/${id}`)
                    .then(res => {
                        this.loadPlayer(res.data.path);
                    })
                    .catch(err => {});
            },
            loadPlayer(code) {
                let tag = document.createElement('script');
                cash(document.body).append(tag);

                tag.onload = () => { this.createPlayer(code); };
                tag.id = 'vimeoplayer';
                tag.src = this.URL_PLAYER;
            },
            createPlayer(code) {
                var sw = cash(window).width();
                var w = '100%';
                var h = '100%';
                
                if (sw > 768) {
                    w = 768;
                    h = 450;
                }

                cash(this.$refs.container).css({
                    width: `${w}px`,
                    height: `${h}px`,
                });

                this.setBtPosition();

                $(window).resize(() => {
                    this.setBtPosition();
                });
                
                this.player = new Vimeo.Player('stage', {
                    width: 768,
                    height: 450,
                    id: code
                });
                
                this.player.on('loaded', this.onPlayerReady.bind(this));
            },
            onPlayerReady(e) {
                this.player.play();
            },
            setBtPosition() {
                const W_WIDTH = cash(window).width();
                const W_HEIGHT = cash(window).height();
                var position = { y: 5, x: W_WIDTH - 65 };

                if (W_WIDTH > 798 && W_HEIGHT > 480) {
                    position = this.$refs.container.getBoundingClientRect();
                    
                    position.y = (W_HEIGHT * 0.5) - 225 - 30;
                    position.x = (W_WIDTH * 0.5) + 399 - 40;
                }

                cash(this.$refs.btClose).css({
                    top: position.top,
                    left: position.left
                });
            },
            destroy() {
                if (this.player) {
					this.player.pause();
					this.player.unload();
					this.player.destroy();
					this.player = null;
				}

                this.isOpened = false;
                cash(this.$refs.container).empty();
                cash(document.body).css({ 'overflow-y': 'unset' });
                $(`script[src*="${this.URL_PLAYER}"]`).remove();
            }
        }
    }
</script>