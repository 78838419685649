<template>
    <div>
        <app-page-header
            icon="fa fa-calendar-check-o"
            title="Celebrações"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Celebrações' },
            ]"
        ></app-page-header>

        <app-datatable
            entity="celebrations"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/celebrations'
                },
                columns: columns
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/celebrations/create'
                },
                edit: {
                    allow: allows.edit,
                    url: '/celebrations/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                }
            }"
        ></app-datatable>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
		},
        props: {
			allows: Object,
			columns: Array,
		},
    }
</script>