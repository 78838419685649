export default {
	texts: {
		min: 'O campo :field deve ter pelo menos :min caracteres!',
		max: 'O campo :field não pode ter mais que :max caracteres!',
		required: 'Preencha o campo :field!',
		same: 'O campo :field2 não corresponde ao campo :field',
		emailInvalid: 'Digite um e-mail válido!',
		checkbox: 'Marque o campo :field',
		charsInvalid: 'Caracteres Inválidos no campo :field',
		atLeast: 'Marque pelo menos uma opção de :field!',
		selectOption: 'Selecione uma opção em :field!',
	},
	isUsernameValid(username) {
		const RE = /^[a-zA-Z]+[a-zA-Z0-9._]+$/;

		return RE.test(username);
	},
	isEmailValid(email) {
		const RE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return RE.test(email);
	},
	install(Vue, options) {
		Vue.prototype.validateForm = (elements) => {
			let element = null;
			let msg = '';
			let isTagsInput = false;
			
			for (let el of elements) {
				element = el;
				
				if (el.type != 'button' && el.type != 'hidden' && !$(el).is(':disabled')) {
					let $el = $(el);
					isTagsInput = $el.prev().hasClass('bootstrap-tagsinput');
					
					if ($el.is('input[type="radio"]') && !$el.is(':checked')) {
						msg = 'Selecione ' + el.placeholder;
					} else if (el.required && el.type == 'file' && !$el.val().length) {
						msg = 'Selecione ' + el.placeholder;
					} else if (el.required && $el.is('select') && !$el.val()) {
						msg = this.texts.selectOption.replace(':field', el.title);
					} else if (el.required && !$el.val().length) {						
						if (isTagsInput) {
							msg = this.texts.selectOption.replace(':field', el.placeholder);

							$el = $el.prev();
							element = $el[0];
						} else {
							msg = this.texts.required.replace(':field', el.placeholder);
						}
					} else if (el.type == 'checkbox' && el.checked == false) {
						let checkbox = $(`input[name="${el.name}"]`);
						
						if (checkbox.length > 1 && checkbox.data('type') == 'checkbox') {
							if (checkbox.filter(':checked').length === 0) {
								return this.texts.atLeast.replace(':field', el.placeholder);
							}
						} else if (el.required) {
							msg = this.texts.checkbox.replace(':field', el.placeholder);
						}
					} else if (
						$el.val().length &&
						el.minLength != -1 &&
						$el.val().length < el.minLength
					) {
						msg = this.texts.min
							.replace(':field', el.placeholder)
							.replace(':min', el.minLength);
					} else if (el.maxLength != -1 && $el.val().length > el.maxLength) {
						msg = this.texts.max
							.replace(':field', el.placeholder)
							.replace(':max', el.maxLength);
					} else if (el.name.includes('password')) {
						let elConfirm = $('[name="confirm_password"]');
						
						if (elConfirm.length && $el.val() !== elConfirm.val()) {
							msg = this.texts.same
								.replace(':field2', elConfirm.attr('placeholder'))
								.replace(':field', el.placeholder);
						}
					} else if (
						el.name.includes('email') &&
						$el.val().length &&
						!this.isEmailValid($el.val())) {
						msg = this.texts.emailInvalid;
					} else if (el.name === 'username' && !this.isUsernameValid($el.val())) {
						msg = this.texts.charsInvalid.replace(':field', el.placeholder);
					}

					if (msg != '' && el.required) break;

					if (isTagsInput) {
						$el = $el.prev();
						element = $el[0];
					}

					element.classList.remove('is-valid', 'is-invalid');
					$el.addClass('is-valid');
				}
			}

			if (msg != '') {
				$(element).focus();
				$(element).addClass('is-invalid');
			}

			return msg;
		};
	}
}
