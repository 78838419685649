<template>
	<div :id="name" :class="containerClass">
		<label v-if="label" :for="name">
			{{ label }}
			<a
				v-if="help"
				tabindex="0"
				class="btn btn-outline-info btn-circle btn-circle-xm mb-1"
				data-toggle="popover"
				data-trigger="focus"
				role="button"
				:data-content="help.text"
			>
				<i class="fa fa-question"></i>
			</a>
		</label>
		<div ref="element" class="form-group">
			<input
				type="text"
        		:id="name + '-input'"
				:class="[{ 'is-invalid' : error }, className]"
				:title="title"
				:placeholder="placeholder"
				:required="required"
			>
			<div v-if="error" class="invalid-feedback">
				{{ error }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				data: [],
				selectedData: [],
			}
		},
		props: {
			id: String,
			placeholder: String,
			label: String,
			title: String,
			name: String,
			values: { type: Array, default: () => { return []; } },
			urlData: String,
			className: { type: String, default: 'form-control' },
			containerClass: { type: String, default: 'col-12' },
			required: { type: Boolean, default: false },
			help: Object,
			error: String,
			callback: { type: Function, default: null },
			fields: {
				type: Object,
				default: () => {
					return {
						value: 'id',
						text: 'title',
					}
				}
			},
		},
		mounted() {
			this.loadData();
		},
		methods: {
			loadData() {
				axios.get(this.urlData)
					.then(res => {
						this.data = res.data;
						this.init();
					})
					.catch(err => {});
			},
			init() {
				let list = new Bloodhound({
					datumTokenizer: Bloodhound.tokenizers.obj.whitespace(this.fields.text),
					queryTokenizer: Bloodhound.tokenizers.whitespace,
					local: this.data
				});
				
				$(`#${this.name}-input`).tagsinput({
					tagClass: 'btn btn-danger btn-sm',
					itemValue: this.fields.value,
					itemText: this.fields.text,
					typeaheadjs: {
						display: this.fields.text,
						source: list,
					},
					freeInput: false
				});

				cash(this.$refs.element)
					.find('.bootstrap-tagsinput')
					.addClass('form-control w-auto h-auto');

				$(`#${this.name}-input`).on('itemAdded', (e) => {
					this.selectedData.push(e.item);
					
					if (this.callback) {
						this.callback(this.selectedData);
					}
				});

				$(`#${this.name}-input`).on('itemRemoved', (e) => {
					let index = this.selectedData
						.map(o => { return o.value; })
						.indexOf(e.item[this.fields.value]);
					this.selectedData.splice(index, 1);
					
					if (this.callback) {
						this.callback(this.selectedData);
					}
				});

				if (this.values) {
					this.loadValues(this.values);
				}
			},
			loadValues(data) {
				data = data.map(d => {
					let o = {};
					o[this.fields.value] = d[this.fields.value];
					o[this.fields.text] = d[this.fields.text];

					return o;
				});
				
				for (let item of data) {
					$(`#${this.name}-input`).tagsinput('add', item);
				}
			}
		}
	}
</script>
