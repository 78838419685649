<template>
    <div>
        <app-page-header
            title="Salas"
            icon="fa fa-graduation-cap"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Salas' },
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            entity="classrooms"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                print: true,
                columnsToPrint: [1,2,3,4],
                ajax: {
                    url: '/web/classrooms',
                },
                columns: columns,
                searchCols: initFilter,
                btFilter: schools.length > 1
            }"
            :actions="{
                qrcode: {
                    allow: allows.qrcode,
                    url: '/students/print_qrcode?classroom_id=*',
                },
                pass: {
                    allow: allows.pass,
                    url: '/students/print_password?classroom_id=*',
                },
                student: {
                    allow: allows.student,
                    url: '/students?classroom_id=*',
                },
                create: {
                    allow: allows.create,
                    url: '/classrooms/create',
                },
                show: {
                    allow: allows.show,
                    callback: onShow,
                },
                edit: {
                    allow: allows.edit,
                    url: '/classrooms/*/edit',
                },
                settings: {
                    allow: allows.settings,
                    url: '/classrooms/*/settings'
                },
                delete: {
                    allow: allows.delete,
                    url: '*',
                },
            }"
            :filters="[
                {
                    name: 'school_id',
                    type: 'select',
                    index: 3,
                    options: schools,
                    liveSearch: true,
                    value: school_id,
                }
            ]"
        ></app-datatable>

        <app-classroom-show :isTeacher="this.isTeacher" ref="classroomShow"></app-classroom-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import ClassroomShow from './ClassroomShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': DataTable,
            'app-classroom-show': ClassroomShow,
        },
        data() {
            return {
                schools: [],
                school_id: null,
                initFilter: []
            }
        },
        props: {
            allows: Object,
            columns: Array,
            isTeacher: Boolean,
            params: Object | Array,
        },
        mounted() {
            this.school_id = this.params.school_id;
            this.loadInitFilter();

            this.loadSchools();

            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
        methods: {
            loadInitFilter() {
                loop1:
                for (let i = 0; i < this.columns.length; i++) {
                    let col = this.columns[i].name;
                loop2:
                    for (let param of Object.keys(this.params)) {
                        if (param == 'id') continue;

                        if (String(col).indexOf(param) != -1) {
                            this.initFilter.push({ 'search': this.params[param] });
                            continue loop1;
                        }
                    }

                    this.initFilter.push(null);
                }
            },
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        this.schools = res.data;
                    })
                    .catch(err => {});
            },
            onShow(id) {
                this.$refs.classroomShow.open(id);
            }
        }
    }
</script>