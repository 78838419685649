<template>
	<div>
		<app-page-header
			icon="fa fa-play-circle"
			title="Cadastrar Animação"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/animations', title: 'Animações' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

		<div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
							<div class="row">
								<div class="col-4 col-xl-3 col-md-6 mb-3">
                                    <img
                                        :src="tempImage"
                                        id="thumb" 
                                        class="thumb-activities img-fluid rounded"
                                    >
                                    <app-button
                                        className="btn-circle-sm btn-table jn-btn-camera"
                                        color="warning"
                                        icon="camera"
                                        title="Inserir Imagem"
                                        :outline="false"
                                        :callback="this.openEditImage"
                                    ></app-button>
                                </div>
								<app-input
                                    title="mínimo 2 caracters"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="65"
                                    :minLength="2"
                                    :required="true"
                                ></app-input>
                                <app-input
                                    title="mínimo 2 caracters"
                                    label="Assunto"
                                    name="subject"
                                    placeholder="Assunto"
                                    :maxLength="65"
                                    :minLength="2"
                                    :required="true"
                                ></app-input>
								<app-input
                                    title="mínimo 2 caracters"
                                    label="Url (ex: lEhPzvqJ08Q)"
                                    name="path"
                                    placeholder="Url"
                                    :maxLength="50"
                                    :minLength="2"
                                    :required="true"
                                ></app-input>
								<app-textarea
                                    name="description"
                                    label="Descrição"
                                    placeholder="Descrição"
                                    :maxLength="300"
                                    resize="none"
                                ></app-textarea>
							</div>
							<div class="row">
                                <app-input-tags
                                    ref="tags"
                                    name="tags"
                                    label="Tags"
                                    urlData="/web/tags/list"
                                    :fields="{ value: 'value', text: 'label' }"
                                ></app-input-tags>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
						</form>

						<app-image-select
                            ref="imageSelect"
                            :imageLoaded="imageLoaded"
                        ></app-image-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import Input from '../forms/InputComponent';
    import Select from '../forms/SelectComponent';
    import Textarea from '../forms/TextareaComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import ImageSelect from '../ImageSelectComponent';
	import InputTags from '../forms/InputTagsComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-input': Input,
            'app-select': Select,
            'app-textarea': Textarea,
            'app-button': Button,
            'app-link': Link,
            'app-image-select': ImageSelect,
			'app-input-tags': InputTags,
		},
		data() {
			return {
				DEFAULT_IMAGE: '/assets/img/default_thumbs/animation.png',
                tempImage: '',
			}
		},
		props: {
			backUrl: String,
		},
		mounted() {
			this.tempImage = this.DEFAULT_IMAGE;
		},
		methods: {
			openEditImage() {
                this.$refs.imageSelect.open();
            },
			imageLoaded(image) {
                if (image) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                       this.tempImage = e.currentTarget.result;
                    }
                    reader.readAsDataURL(image);
                } else {
                    this.tempImage = this.DEFAULT_IMAGE;
                }
            },
			onSubmit() {
				let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('thumb', this.$refs.imageSelect.file);

                    for (let tag of this.$refs.tags.selectedData) {
                        formData.append('tags[]', tag.value);
                    }

                    axios.post(`/web/animations`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/animations';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
			}
		}
	}
</script>