var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-th-list","title":"Módulos","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Módulos' }
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"modules","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"withStatusFilter":true,"options":{
            ajax: {
                url: '/web/modules'
            },
            columns: _vm.columns
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/modules/create'
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/modules/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            },
            class_plan: {
                allow: _vm.allows.classPlan,
                url: '*',
            }
        }}}),_vm._v(" "),_c('app-module-show',{ref:"moduleShow"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }