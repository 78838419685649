var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-tag","title":"Tags","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Tags' },
        ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"tags","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":3,"options":{
            ajax: {
                url: '/web/tags',
            },
            columns: _vm.columns,
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/tags/create',
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/tags/*/edit',
            },
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }