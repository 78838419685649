<template>
    <div>
        <app-page-header
			icon="fa fa-ticket"
			title="Cadastrar Ticket"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/tickets', title: 'Tickets' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
                        <form ref="form">
                            <div class="row">
                                <app-select
                                    name="department"
                                    label="Setor"
                                    title="Setor"
                                    :options="departments"
                                    :required="true"
                                    empty="false"
                                ></app-select>
                                <app-input
                                    title="mínimo 4 caracters"
                                    name="subject"
                                    label="Assunto"
                                    placeholder="Assunto"
                                    :maxLength="50"
                                    :minLength="4"
                                    :required="true"
                                ></app-input>
                                <app-textarea
                                    ref="message"
                                    name="message"
                                    placeholder="Mensagem"
                                    :required="true"
                                    :maxLength="300"
                                    :rows="5"
                                    resize="none"
                                ></app-textarea>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import TextArea from '../forms/TextareaComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-select': Select,
            'app-input': Input,
            'app-textarea': TextArea,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
            return {
                departments: [],
            }
        },
        props: {
			backUrl: String,
		},
        mounted() {
            this.loadDepartments();
        },
        methods: {
            loadDepartments() {
                axios.get('/web/tickets/departments')
                    .then(res => {
                        this.departments = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/tickets`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Ticket criado com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/tickets';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>