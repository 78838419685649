export default {
    getButton(type, icon, title, href, rowId = null, color = 'info', callback = null) {
        return `
            <a
                class="btn btn-outline-${color} ${icon == 'file-pdf-o' ? 'colorbox' : ''} btn-circle btn-circle-sm btn-table"
                data-content="${title}"
                href="${href}"
                data-toggle="popover"
                data-type="${type}"
                ${(rowId) ? ` data-id="${rowId}"` : ''}
                ${(callback) ? ` data-function="${callback}"` : ''}
            >
                <i class="fa fa-${icon}"></i>
            </a>
        `;
    },
    install(Vue, options) {
        Vue.prototype.formatDatatablesButtons = (data) => {
            let actions = {};

            if (data.hasOwnProperty('qrcode') && data.qrcode.allow) {
                actions.qrcode = this.getButton(
                    'info',
                    'qrcode',
                    'Imprimir QRCODE',
                    `${data.qrcode.url}`,
                );
            }

            if (data.hasOwnProperty('pass') && data.pass.allow) {
                actions.pass = this.getButton(
                    'info',
                    'key',
                    'Imprimir senhas',
                    `${data.pass.url}`,
                );
            }

            if (data.hasOwnProperty('student') && data.student.allow) {
                actions.student = this.getButton(
                    'info',
                    'user',
                    'Alunos',
                    `${data.student.url}`,
                );
            }

            if (data.hasOwnProperty('indicate') && data.indicate.allow) {
                actions.indicate = this.getButton(
                    'success',
                    'thumb-tack',
                    'Indicar',
                    `${data.indicate.url}`,
                    null,
                    'success'
                );
            }

            if (data.hasOwnProperty('classrooms') && data.classrooms.allow) {
                actions.classrooms = this.getButton(
                    'info',
                    'graduation-cap',
                    'Salas',
                    `${data.classrooms.url}`,
                    null,
                    'primary'
                );
            }

            if (data.hasOwnProperty('preview') && data.preview.allow) {
                actions.preview = this.getButton(
                    'preview',
                    'play',
                    'Pré-visualizar',
                    `javascript:`,
                    '*',
                    'secondary'
                );
            }

            if (data.hasOwnProperty('class_plan') && data.class_plan.allow) {
                actions.class_plan = this.getButton(
                    'info',
                    'file-pdf-o',
                    'Plano de aula',
                    '*',
                );
            }
				
            if (data.hasOwnProperty('show') && data.show.allow) {
                actions.show = this.getButton(
                    'show',
                    'eye',
                    data.show.title ?? 'Visualizar',
                    'javascript:',
                    '*'
                );
            }

            if (data.hasOwnProperty('edit') && data.edit.allow) {
                actions.edit = this.getButton(
                    'edit',
                    'pencil',
                    'Editar',
                    data.edit.url,
                    null,
                    'warning'
                );
            }

            if (data.hasOwnProperty('settings') && data.settings.allow) {
                actions.settings = this.getButton(
                    'settings',
                    'cog',
                    'Configurações',
                    `${data.settings.url}`,
                    null,
                    'warning'
                );
            }

            if (data.hasOwnProperty('migrate') && data.migrate.allow) {
                actions.migrate = this.getButton(
                    'migrate',
                    'exchange',
                    'Migrar alunos',
                    `${data.migrate.url}`,
                    null,
                    'warning'
                );
            }

            if (data.hasOwnProperty('reopen') && data.reopen.allow) {
                actions.reopen = this.getButton(
                    'reopen',
                    'envelope-open-o',
                    'Reabrir',
                    `javascript:`,
                    '*',
                    'success'
                );
            }

            if (data.hasOwnProperty('close') && data.close.allow) {
                actions.close = this.getButton(
                    'close',
                    'envelope-o',
                    'Fechar',
                    `javascript:`,
                    '*',
                    'danger'
                );
            }

            if (data.hasOwnProperty('chart') && data.chart.allow) {
                actions.chart = this.getButton(
                    'info',
                    'line-chart',
                    'Detalhes',
                    `${data.chart.url}`,
                );
            }

            if (data.hasOwnProperty('delete') && data.delete.allow) {
                actions.delete = this.getButton(
                    'delete',
                    'trash',
                    'Deletar',
                    'javascript:',
                    '*',
                    'danger',
                    'onDelete'
                );
            }
            
            return actions;
        };
    }
}