<template>
	<div>
		<app-page-header
            icon="fa fa-music"
            title="Cantigas"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Cantigas' },
            ]"
        ></app-page-header>

		<app-datatable
            entity="songs"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :withStatusFilter="true"
            :options="{
                ajax: {
                    url: '/web/songs',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/songs/create',
                },
                show: {
                    allow: allows.show,
                    callback: onShow,
                },
                edit: {
                    allow: allows.edit,
                    url: '/songs/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*',
                },
                preview: {
                    allow: allows.preview,
                    callback: onPreview,
                },
                indicate: {
                    allow: allows.indicate,
                    url: '/indications/create?activity_id=@',
                },
            }"
        ></app-datatable>

		<app-song-show ref="songShow"></app-song-show>
        <app-song-preview ref="songPreview"></app-song-preview>
	</div>
</template>

<script>
	import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
	import SongShow from './SongShowComponent';
    import SongPreview from './SongPreviewComponent';

	export default {
		components: {
			'app-page-header': PageHeader,
			'app-datatable': DataTable,
			'app-song-show': SongShow,
            'app-song-preview': SongPreview,
		},
		props: {
            params: Object | Array,
			allows: Object,
			columns: Array,
		},
        mounted()
        {
            if (this.params.hasOwnProperty('id')) {
                this.onShow(this.params.id);
            }
        },
		methods: {
			onShow(id) {
				this.$refs.songShow.open(id);
			},
            onPreview(id) {
                this.$refs.songPreview.load(id);
            }
		}
	}
</script>